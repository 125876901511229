import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import config from '../../config';
import { Calendar } from 'primereact/calendar';

function UpdateMenuDetail() {
    const { id } = useParams(); // Get the project ID from the URL
    const navigate = useNavigate();
    const [menu, setMenu] = useState({ title:'', timing: '', galleryImages: [] });
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState(''); 

    useEffect(() => {
        const fetchLocationDetails = async () => {
            try {
                const response = await axios.get(`${config.BASE_URL}/api/admin/get-menu-item/${id}`, { withCredentials: true });
                setMenu(response.data);
            } catch (err) {
                setError('Failed to fetch location details');
            }
        };

        fetchLocationDetails();
    }, [id]);


    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', menu.name);
        formData.append('type', menu.type);
        formData.append('menuId', menu.menuId);
        formData.append('shortDescription', menu.shortDescription);
        formData.append('price', menu.price);


        try {
            const response = await axios.put(`${config.BASE_URL}/api/admin/update-menu-item/${id}`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });

            // Update the project state with the response data
            setMenu(response.data.menuItem);
            setSuccessMessage('Location updated successfully!'); 

            setTimeout(() => {
                setSuccessMessage(''); 
            }, 1500);
        } catch (err) {
            setError('Failed to update location');
        }
    };


    return (
        <div className="dashboard update_detail_wrap">
            <div className="container">
                <h1 className="dash_title">Edit Location</h1>
                <div className="back_btn_wrap">
                    <button className="back-btn" onClick={() => navigate(-1)}>Go Back</button>
                </div>
                {menu ? (
                    <form onSubmit={handleFormSubmit}>
                        <div className="form-login">
                            <div className="add_field">
                                <h2>Name</h2>
                                <input
                                    type="text"
                                    value={menu.name}
                                    onChange={(e) => setMenu({ ...menu, name: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="add_field">
                                <h2>Type</h2>
                                <input
                                    type="text"
                                    value={menu.type}
                                    onChange={(e) => setMenu({ ...menu, type: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="add_field">
                                <h2>Description</h2>

                                <input
                                    type="text"
                                    value={menu.shortDescription}
                                    onChange={(e) => setMenu({ ...menu, shortDescription: e.target.value })}
                                    required
                                />
                            </div>
            
                            <div className="add_field">
                                <h2>Price</h2>
                                <input
                                    type="text"
                                    value={menu.price}
                                    onChange={(e) => setMenu({ ...menu, price: e.target.value })}
                                    required
                                />
                            </div>
                        
                        </div>
                        <div className="add_project">
                            <button type="submit">Update Item</button>
                        </div>
                  </form>
                ) : (
                    <h5>Location not found!</h5>
                )}

                <p className="successMessage">{successMessage}</p>
            </div>
        </div>
    ); 
}

export default UpdateMenuDetail;
