import React from "react";
import DashboardHeader from '../../components/admincomponents/DashboardHeader';
import UpdateLocationDetail from '../../components/admincomponents/UpdateLocationDetail';

function UpdateLocation({onLogout}) {
  return (
    <>
      <DashboardHeader onLogout={onLogout}/>
      <UpdateLocationDetail />            
    </>
  );
}

export default UpdateLocation;
