import React from "react";
import BannerDeskImage from '../assets/images/about-team-desk.jpg';
import BannerMobImage from '../assets/images/about-team-mobile.jpg';
import MobileBannerImage from '../assets/images/meet-banner-mobile.jpg';
import upWhiteArrow from '../assets/images/upWhiteArrow.svg';

function aboutTeam(){
    return(
        <>
        <div className="about-team position-relative">    
        <picture>
        <source media="(max-width: 767px)" srcSet={BannerMobImage} />
        <img 
            src={BannerDeskImage} 
            alt="Banner Image" 
            className="position-absolute h-100 w-100 s-0 t-0 meetbgimg" 
        />
        </picture>

                     
            <div className="aboutteam_banner_content position-relative plr-100 text-center fw-200">  
                <div className="container text-white position-relative">
                    <div className="aboutteam_banner_content_inr">
                        <div className="aboutteam_banner_content_top">
                            <h2 className="text-uppercase m-0 font-18">Team</h2>
                            <p>Building leaders and creating opportunities is the hallmark of how we manage the team. It’s a recognising the person behind the food and coffee first mentality. We lead through encouraging our team to discover, step into the unknown and develop, a culture and mindset that transcends food and coffee.</p>
                        </div>
                        <div className="aboutteam_banner_content_bottom">
                            <h2 className="text-uppercase m-0 font-18">PEOPLE</h2>
                            <p>We are committed to providing the space, the service and the experience which is brought to life by its people. We are grateful for the community and the people who share the same passion and value what we are doing. You are the fourth pillar of what makes us Little Cove Espresso.</p>
                        </div>
                    </div>
                </div>
            </div>             
        </div>        
        </>
    );
}
export default aboutTeam;