import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import { Calendar } from 'primereact/calendar';

function AddLocation() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    m_f_startTime: '',
    m_f_endTime: '',
    // s_s_startTime: '',
    // s_s_endTime: '',
    featuredImage: null,
    galleryImages: null,
    bannerImage: null,
    description: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    country: '',
    phone_number: '',
  });

  // const hours = Array.from({ length: 24 }, (v, i) => String(i).padStart(2, "0"));
  const hours = Array.from({ length: 24 * 2 }, (_, i) => {
    const hour = String(Math.floor(i / 2)).padStart(2, "0");
    const minutes = i % 2 === 0 ? "00" : "30";
    return `${hour}:${minutes}`;
  });
  

  const handleInputChange = (e) => {
    const { name, value, files } = e;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files : value // Set files if they exist, otherwise set value
    }));
  };

  const handleAddLocation = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('title', formData.title);
    data.append('m_f_startTime', formData.m_f_startTime);
    data.append('m_f_endTime', formData.m_f_endTime);

    // data.append('s_s_startTime', formData.s_s_startTime);
    // data.append('s_s_endTime', formData.s_s_endTime);

    data.append('description', formData.description);
    data.append('addressLine1', formData.addressLine1);
    data.append('addressLine2', formData.addressLine2);
    data.append('city', formData.city);
    data.append('state', formData.state);
    data.append('country', formData.country);
    data.append('phone_number', formData.phone_number);

    // Handle multiple file uploads for images
    if (formData.galleryImages) {
      for (let i = 0; i < formData.galleryImages.length; i++) {
        data.append('galleryImages', formData.galleryImages[i]);
      }
    }
    // Single file upload for featured image
    if (formData.featuredImage) {
      data.append('featuredImage', formData.featuredImage[0]);
    }
    // Single file upload for banner image
    if (formData.bannerImage) {
      data.append('bannerImage', formData.bannerImage[0]);
    }

    if (formData.locationVideo) {
      data.append('locationVideo', formData.locationVideo[0]);
    }

    try {
      const response = await axios.post(`${config.BASE_URL}/api/admin/add-location`, data, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        const token = localStorage.getItem('token');
        if (token) {
          navigate('/admin/dashboard');
        } else {
          navigate('/admin/login');
        }
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to add Location');
    }
  };

  // console.log("formData: ", formData);

  return (
    <div className="dashboard">
      <div className="container">
        <h1 className="dash_title">Dashboard</h1>
        <div className="main-section">
        <div className="back_btn_wrap">
          <button className="back-btn" onClick={() => navigate(-1)}>Go Back</button>
        </div>
          <div className="main-inner add_project_main">
            <form onSubmit={handleAddLocation} encType="multipart/form-data">
              <div className="form-login">
                <div className="add_field titleField">
                  <h2>Location Title</h2>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={(e) => handleInputChange(e.target)}
                    required
                  />
                </div>

                <div className="add_field textareaField">
                  <h2>Description</h2>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={(e) => handleInputChange(e.target)}
                    required
                  />
                </div>

                <div className="add_field">
                  <h2>M-S Start Time</h2>
                  {/* <Calendar id="calendar-timeonly_starttime" name="startTime" value={formData.startTime} onChange={(e) => handleInputChange(e.target)} timeOnly /> */}
                  <select id="m_f_startTime" value={formData.m_f_startTime} name="m_f_startTime" onChange={(e) => handleInputChange(e.target)}>
                    {hours.map((hour) => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="add_field">
                  <h2>M-S End Time</h2>
                  {/* <Calendar id="calendar-timeonly_endtime" name="endTime" value={formData.endTime} onChange={(e) => handleInputChange(e.target)} timeOnly /> */}
                  <select id="m_f_endTime" value={formData.endTime} name="m_f_endTime" onChange={(e) => handleInputChange(e.target)}>
                    {hours.map((hour) => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </select>
                </div>

                {/* <div className="add_field">
                  <h2>S-S Start Time</h2> */}
                  {/* <Calendar id="calendar-timeonly_starttime" name="startTime" value={formData.startTime} onChange={(e) => handleInputChange(e.target)} timeOnly /> */}
                  {/* <select id="s_s_startTime" value={formData.s_s_startTime} name="s_s_startTime" onChange={(e) => handleInputChange(e.target)}>
                    {hours.map((hour) => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </select>
                </div> */}
                {/* <div className="add_field">
                  <h2>S-S End Time</h2> */}
                  {/* <Calendar id="calendar-timeonly_endtime" name="endTime" value={formData.endTime} onChange={(e) => handleInputChange(e.target)} timeOnly /> */}
                  {/* <select id="s_s_endTime" value={formData.s_s_endTime} name="s_s_endTime" onChange={(e) => handleInputChange(e.target)}>
                    {hours.map((hour) => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </select>
                </div> */}

                <div className="add_field">
                  <h2>Address line 1</h2>
                  <input
                    type="text"
                    name="addressLine1"
                    value={formData.addressLine1}
                    onChange={(e) => handleInputChange(e.target)}
                    required
                  />
                </div>

                <div className="add_field">
                  <h2>Address line 2</h2>
                  <input
                    type="text"
                    name="addressLine2"
                    value={formData.addressLine2}
                    onChange={(e) => handleInputChange(e.target)}
                    required
                  />
                </div>

                <div className="add_field">
                  <h2>City</h2>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={(e) => handleInputChange(e.target)}
                    required
                  />
                </div>

                <div className="add_field">
                  <h2>State</h2>
                  <input
                    type="text"
                    name="state"
                    value={formData.state}
                    onChange={(e) => handleInputChange(e.target)}
                    required
                  />
                </div>

                <div className="add_field">
                  <h2>Country</h2>
                  <input
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={(e) => handleInputChange(e.target)}
                    required
                  />
                </div>

                <div className="add_field">
                  <h2>Phone number</h2>
                  <input
                    type="text"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={(e) => handleInputChange(e.target)}
                    required
                  />
                </div>

                <div className="add_field">
                  <h2>Gallery Images</h2>
                  <div className="form-group">
                    <div className="file-upload">
                      <input
                        type="file"
                        className="form-control"
                        name="galleryImages"
                        multiple
                        onChange={(e) => handleInputChange(e.target)}
                        required
                      />
                      <i className="fa fa-camera i-pic-upload"></i>
                    </div>
                  </div>
                </div>
                <div className="add_field">
                  <h2>Banner Image</h2>
                  <div className="form-group">
                    <div className="file-upload">
                      <input
                        type="file"
                        className="form-control"
                        name="bannerImage"
                        onChange={(e) => handleInputChange(e.target)}
                        required
                      />
                      <i className="fa fa-camera i-pic-upload"></i>
                    </div>
                  </div>
                </div>
                <div className="add_field">
                  <h2>Featured Image</h2>
                  <div className="form-group">
                    <div className="file-upload">
                      <input
                        type="file"
                        className="form-control"
                        name="featuredImage"
                        onChange={(e) => handleInputChange(e.target)}
                        required
                      />
                      <i className="fa fa-camera i-pic-upload"></i>
                    </div>
                  </div>
                </div>

                {/* <div className="add_field">
                  <h2>Video</h2>
                  <div className="form-group">
                    <div className="file-upload">
                      <input
                        type="file"
                        className="form-control"
                        name="locationVideo"
                        onChange={(e) => handleInputChange(e.target)}
                        required
                      />
                      <i className="fa fa-camera i-pic-upload"></i>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="add_project">
                <button type="submit">Add Location</button>
              </div>
            </form>
            {error && <p className="error-message">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddLocation;
