import React from "react";
import Slider from "react-slick";
import Slide1 from '../assets/images/home-gallery-slide1.jpg';
import Slide2 from '../assets/images/home-gallery-slide2.jpg';
import Slide3 from '../assets/images/home-gallery-slide3.jpg';
import Slide4 from '../assets/images/home-gallery-slide4.jpg';
import Slide5 from '../assets/images/home-gallery-slide5.jpg';
import Slide6 from '../assets/images/home-gallery-slide6.jpg';


export default function homeGallerySlider() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true, // Enable peek view
    centerPadding: "15%",
    responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            centerPadding: "28%",
          }
        }
      ]
  };
  return (
    <div className="home_gallery_slider">
        <Slider {...settings}>
        <div className="home_gallery_common_slide">
            <div className="home_gallery_common_slideinr">
                <img src={Slide1} alt="Right Image" className="" />
            </div>        
        </div>
        <div className="home_gallery_common_slide">
            <div className="home_gallery_common_slideinr">
                <img src={Slide2} alt="Right Image" className="" />
            </div>        
        </div>
        <div className="home_gallery_common_slide">
            <div className="home_gallery_common_slideinr">
                <img src={Slide3} alt="Right Image" className="" />
            </div>        
        </div>
        <div className="home_gallery_common_slide">
            <div className="home_gallery_common_slideinr">
                <img src={Slide4} alt="Right Image" className="" />
            </div>        
        </div>
        <div className="home_gallery_common_slide">
            <div className="home_gallery_common_slideinr">
                <img src={Slide5} alt="Right Image" className="" />
            </div>        
        </div>
        <div className="home_gallery_common_slide">
            <div className="home_gallery_common_slideinr">
                <img src={Slide6} alt="Right Image" className="" />
            </div>        
        </div>
        </Slider>
    </div>
  );
}