import React from "react";
import { NavLink } from 'react-router-dom';
import LeftImage from '../assets/images/buy-left.jpg';
import LeftVideo from '../assets/videos/new-home-short-video.mp4';
import RightImage from '../assets/images/home-shtlrg-large.jpg';

function shortLargeImage(){
    return(
        <>
        <div className="short_large_image"> 
            <div className="container"> 
                <div className="short_large_image_inner d-flex align-items-center">
                    <div className="short_image_column">
                    <video autoPlay muted loop playsInline>
                        <source src={LeftVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                    </div>
                    <div className="large_image_column">
                        <div className="large_image_column_inner position-relative">                                
                            <img src={RightImage} alt="Right Image" className="w-100" />
                            {/* <NavLink className="position-absolute buybtn position-absolute " exact to="">buy coffee</NavLink> */}
                        </div>
                    </div>
                </div>
            </div>             
        </div>        
        </>
    );
}
export default shortLargeImage;