import React, { useState, useEffect } from 'react';
import { LocationMenu } from '../components/LocationSlider';
// import menuimg1 from '../assets/images/menubg.png';
// import upBlackarrow from '../assets/images/up-blackarrow.svg';
// import saiGallery1 from '../assets/images/sai-gallery1.png';
// import saiGallery2 from '../assets/images/sai-gallery2.png';
// import saiGallery3 from '../assets/images/sai-gallery3.png';
// import townGallery1 from '../assets/images/town-gallery1.png';
import partnervideo from '../assets/images/partner-video.mp4';
import Slider from 'react-slick';
import LocationsGallery from '../components/LocationsGallery';
import Modal from '../components/Modal';
import axios from "axios";
import config from '../config';
import Layout from './Layout';

export default function Locations() {
    const [locations, setLocations] = useState([]);
    const [menus, setmenus] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({
        menubg: '',
        title: '',
        time: '',
        time1: '',
        moreLink: '',
        moreText: '',
        menuItems: [],
        menuId: ''
    });
    

    // const locations = [
    //     {
    //       imgsrc: [saiGallery1],
    //       menubg: [menuimg1],
    //       title: "Sai Kung",
    //       status: "open now",
    //       time: "M-F: 10-6pm",
    //       time1: "S+S: 10-6pm",
    //       menu: "MENU",
    //       moreLink: "#",
    //       moreText: "MORE",
    //       images: [saiGallery1, saiGallery2, saiGallery3],
    //       icon: [upBlackarrow],
    //       menuItems: [
    //         {
    //             title: 'SOMETHING LIGHT',
    //             submenu: [
    //               { name: 'Croissant', description: 'with Butter & Blueberry Jam +6', price: '28' },
    //               { name: 'Pain Au Chocolat', price: '28' },
    //               { name: 'Bagel', description: 'Everything - Sesame, Poppy, Onion with Butter & Jam / Cream Cheese', price: '58' },
    //             ]
    //         },
    //         {
    //             title: 'BREAKFAST BOWLS',
    //             submenu: [
    //                 { name: 'Granola Bowl', price: '58' },
    //                 { name: 'Acai Bowl', price: '68' }
    //             ]
    //         },
    //         {
    //             title: 'SMOOTHIE BOWLS',
    //             submenu: [
                    
    //             ]
    //         },
    //         {
    //             title: 'BRUNCH',
    //             submenu: [
                    
    //             ]
    //         },
    //         {
    //             title: 'SOMETHING SWEET',
    //             submenu: [
                    
    //             ]
    //         },
    //         {
    //             title:  'EXTRAS',
    //             submenu: [
                    
    //             ]
    //         },
    //         {
    //             title: 'KIDS MENU',
    //             submenu: [
                    
    //             ]
    //         }
    //       ],
    //     },
    //     {
    //       imgsrc: [townGallery1],
    //       menubg: [townGallery1],
    //       title: "Kennedy Town",
    //       status: "open now",
    //       time: "M-F: 9-5pm",
    //       time1: "S+S: 9-5pm",
    //       menu: "MENU",
    //       moreLink: "#",
    //       moreText: "MORE",
    //       images: [saiGallery3, townGallery1, saiGallery1],
    //       icon: [upBlackarrow],
    //       menuItems: [
    //         {
    //             title: 'SOMETHING LIGHT',
    //             submenu: [
    //               { name: 'Croissant', description: 'with Butter & Blueberry Jam +6', price: '28' },
    //               { name: 'Pain Au Chocolat', price: '28' },
    //               { name: 'Bagel', description: 'Everything - Sesame, Poppy, Onion with Butter & Jam / Cream Cheese', price: '58' },
    //             ]
    //         },
    //         {
    //             title: 'BREAKFAST BOWLS',
    //             submenu: [
    //                 { name: 'Granola Bowl', price: '58' },
    //                 { name: 'Acai Bowl', price: '68' }
    //             ]
    //         },
    //         {
    //             title: 'SMOOTHIE BOWLS',
    //             submenu: [
                    
    //             ]
    //         },
    //         {
    //             title: 'BRUNCH',
    //             submenu: [
                    
    //             ]
    //         },
    //         {
    //             title: 'SOMETHING SWEET',
    //             submenu: [
                    
    //             ]
    //         },
    //         {
    //             title:  'EXTRAS',
    //             submenu: [
                    
    //             ]
    //         },
    //         {
    //             title: 'KIDS MENUS',
    //             submenu: [
                    
    //             ]
    //         }
    //       ],
    //     },
    // ];

    const fetchLocations = async () => {
        try {
            const response = await axios.get(`${config.BASE_URL}/api/admin/get-locations`, { withCredentials: true });
            
            if(response.data){
                const menuItems = {}
                await Promise.all(response.data.map(async (item, key) => {
                    const menuData = await fetchMenuItems(item.menuId)
                    menuItems[item._id] = menuData
                }));
                
                setmenus(menuItems);
                setLocations(response.data);
            }

        } catch (err) {
            console.log('Failed to fetch locations');
        } 
    };

    const fetchMenuItems = async (menuId) => {
        try {
            const response = await axios.get(`${config.BASE_URL}/api/admin/get-menu-items-count/${menuId}`, { withCredentials: true });
            return response.data;
        } catch (err) {
            console.log('Failed to fetch menu items');
        }
    };

    const sliderSettings = {
        dots: false,
        arrows: false,
        infinite: true,          // Disable infinite scrolling
        slidesToShow: 2.5,        // Show 2.5 slides to make the 3rd slide partially visible
        slidesToScroll: 1,        // Scroll one slide at a time
        centerMode: false, 
        // rtl: true,
        autoplay:true,
        autoplaySpeed: 2000,
    };

    const handleMenuClick = (location) => {
        // const startTime = new Date(location.startTime);
        // const endTime = new Date(location.endTime);

        // const start = startTime.getHours();
        // const end = endTime.getHours();

        const start = parseInt(location.m_f_startTime);
        const end = parseInt(location.m_f_endTime);

        setModalContent({
            menubg: `${config.BASE_URL}${location.featuredImage}`,
            title: ` ${location.title} Menu`,
            time: `${start % 12}-${end % 12}${end > 12 ? "pm" : "am"}`,
            moreLink: `/single-location/${location.slug}`,
            menuId: location.menuId,
        });

        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        fetchLocations();
    }, []);

    return (
        <>
        <Layout>
            <div className='locations-banner page-banner position-relative text-black'>
                <div className="container">
                    <div className="our-story-top-sec d-flex ">
                        <h6>OUR LOCATIONS</h6>
                    </div>
                    <h1>explore our different spaces</h1>
                    <div className='partner-event-list d-flex'>

                    {locations.length > 0 && locations.map((location, index) => (
                        // index <= 2 && (
                            <span className='text-black'>{location.title}</span>
                        // )
                    ))}

                    </div>
                </div>
            </div>
            <div className='location-partner-video partnet-video-sec d-flex align-items-center'>
                <div className='partner-video-left position-relative'>
                    <video width="100%" autoPlay muted loop className="position-absolute">
                        <source src={partnervideo} type="video/mp4" />
                    </video>
                </div>
                <div className='partner-video-right'>
                    <p>Work with LCE to create a custom experience or private event. We partner with hospitality companies, brands, and corporations to host events, popups, and more.</p>
                </div>
            </div>
            <div className='location-menu-secc'>
                {locations.length > 0 && locations.map((location, index) => (
                    <div key={index} className='location-menu-sec-inner'>
                        <div className='location-menu-left'>
                            <LocationMenu
                                key={index}
                                imgsrc={`${config.BASE_URL}${location.featuredImage}`}
                                title={location.title}
                                startTime={location.m_f_startTime}
                                endTime={location.m_f_endTime}
                                s_s_startTime={location.s_s_startTime}
                                s_s_endTime={location.s_s_endTime}
                                setShowModal={setShowModal}
                                setModalContent={setModalContent}
                                menuId={location.menuId}
                                moreLink={`/single-location/${location.slug}`}
                                hideImage={false}

                            />
                        </div>
                        <div className='location-menu-right desktop-view'>
                            <Slider {...sliderSettings}>
                                {location.galleryImages.length > 0 && location.galleryImages.map((image, imgIndex) => (
                                    <div key={imgIndex} className='slider-img'>
                                        <img src={`${config.BASE_URL}${image}`} alt="gallery" />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                ))}
            </div>
            <div className='locations-galleryy mobile-view'>
                <h2>coming soon</h2>
                <LocationsGallery />
            </div>

            {/* Modal Component */}
            {showModal && (
                <Modal
                    show={showModal}
                    handleClose={handleCloseModal}
                    title={modalContent.title}
                    content={modalContent.content}
                    menubg={modalContent.image}
                    time={modalContent.time}
                    time1={modalContent.time1}
                    moreLink={modalContent.moreLink}
                    moreText={"More"}
                    menuId={modalContent.menuId}
                />
            )}
            </Layout>
        </>
    );
}
