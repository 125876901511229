import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import config from '../../config';
import VideoElement from '../../components/VideoElement';

function ProjectDetails() {
    const { id } = useParams(); // Get the project ID from the URL
    const [location, setLocation] = useState(null);
    const [loading, setLoading] = useState(true);
    const [menus, setMenus] = useState(0);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const fetchProjectDetails = async () => {
        try {
            const response = await axios.get(`${config.BASE_URL}/api/admin/get-location/${id}`, { withCredentials: true });
            setLocation(response.data); // Set the fetched project details
            fetchMenuItems(response.data.menuId);
        } catch (err) {
            setError('Failed to fetch location details');
        } finally {
            setLoading(false);
        }
    };

    const fetchMenuItems = async (menuId) => {
        try {
            const response = await axios.get(`${config.BASE_URL}/api/admin/get-menu-items-count/${menuId}`, { withCredentials: true });
            setMenus(response.data);
        } catch (err) {
            setError('Failed to fetch menu items');
        }
    };

    useEffect(() => {
        fetchProjectDetails();
    }, [id]);

    const handleDelete = async () => {
        try {
            await axios.delete(`${config.BASE_URL}/api/admin/delete-location/${id}`, { withCredentials: true });
            // Navigate back to the project list or show a success message
            navigate('/admin/locations');
        } catch (err) {
            setError('Failed to delete the location');
        }
    };

    const renderImageOrVideo = (src) => {
        const extension = src.split(".").pop();
        var mediaType = "";
        const videoExtensions = [
            "mp4",
            "3gp",
            "avi",
            "mkv",
            "webm",
            "mov",
            "flv",
            "wmv",
            "mpeg",
            "mpg",
            "m4v",
          ];
          const imageExtensions = [
            "jpg",
            "jfif",
            "avif",
            "jpeg",
            "svg",
            "png",
            "ico",
            "gif",
            "bmp",
            "webp",
            "tiff",
            "heic",
          ];
    
          if (videoExtensions.includes(extension)) {
            mediaType = "VIDEO";
            
            return (
              <VideoElement url={src}/>
            );
          } else if (imageExtensions.includes(extension)) {
            mediaType = "IMAGE";
            
            return (
              <img src={src} alt="gallery" />
            );
          }
      }


    console.log("menus: ", menus);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="dashboard project_detail_wrap">
            <div className="container">
                <h1 className="dash_title">Dashboard</h1>
                <div className="back_btn_wrap">
                    <button className="back-btn" onClick={() => navigate(-1)}>Go Back</button>
                </div>
                <div className="main-section">
                    <div className="projects">
                        <div className="projects-inner">
                            <div className="detail_item">
                                <h2>Location Title</h2>
                                <div className="detail_item_inner">
                                    <p>{location.title}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Description</h2>
                                <div className="detail_item_inner">
                                    <p>{location.description}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Menus</h2>
                                <div className="detail_item_inner">
                                    <p>{menus && menus.length}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>M-F Timing</h2>
                                <div className="detail_item_inner">
                                    <p>{location.m_f_startTime} </p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>S-S Timing</h2>
                                <div className="detail_item_inner">
                                    <p>{location.m_f_endTime}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Address line 1</h2>
                                <div className="detail_item_inner">
                                    <p>{location.addressLine1}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Address line 2</h2>
                                <div className="detail_item_inner">
                                    <p>{location.addressLine2}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>City</h2>
                                <div className="detail_item_inner">
                                    <p>{location.city}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>State</h2>
                                <div className="detail_item_inner">
                                    <p>{location.state}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Country</h2>
                                <div className="detail_item_inner">
                                    <p>{location.country}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Phone number</h2>
                                <div className="detail_item_inner">
                                    <p>{location.phone_number}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        {location.bannerImage ? ( // No .length because it's a string
                                            <div className="detail_img_item">
                                                <h2>Mobile featured Image</h2>
                                                <div className="banner_img_wrap">
                                                    <img src={`${config.BASE_URL}${location.bannerImage}`} alt="Banner" />
                                                </div>
                                            </div>
                                        ) : (
                                            <p>No image available.</p>
                                        )}



                                        {location.featuredImage ? ( // No .length because it's a string
                                            <div className="detail_img_item">
                                                <h2>Featured Image</h2>
                                                <div className="banner_img_wrap">
                                                    <img src={`${config.BASE_URL}${location.featuredImage}`} alt="Featured" />
                                                </div>
                                            </div>
                                        ) : (
                                            <p>No image available.</p>
                                        )}


                                        {/* {location.locationVideo ? ( // No .length because it's a string
                                            <div className="detail_img_item">
                                                <h2>Video</h2>
                                                <div >
                                                    <video width="100%" autoPlay muted loop className="position-relative">
                                                        <source src={`${config.BASE_URL}${location.locationVideo}`} type="video/mp4" />
                                                    </video>
                                                </div>
                                            </div>
                                        ) : (
                                            <p>No video available.</p>
                                        )} */}


                                    </div>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Gallery Images</h2>
                                <div className="detail_item_inner gallery_inner">
                                    <div className="detail_img_grid">
                                        {location.galleryImages && location.galleryImages.length > 0 ? (
                                            location.galleryImages.map((image, index) => (
                                                <div className="detail_img_item" key={index}>
                                                    {renderImageOrVideo(`${config.BASE_URL}${image}`)}

                                                    {/* <img src={`${config.BASE_URL}${image}`} alt={`Project image ${index}`} /> */}
                                                </div>
                                            ))
                                        ) : (
                                            <p>No images available.</p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="detail_project_btn_wrap">
                                <div className="detail_edit_del">
                                    <div className="btn_item">
                                        <button className="detail_btns" onClick={handleDelete}>Delete</button>
                                    </div>
                                    <div className="btn_item">
                                        <Link to={`/admin/update-location/${location._id}`} className="update_detail detail_btns">Edit</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProjectDetails;
