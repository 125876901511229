import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import config from '../../config';
import { Calendar } from 'primereact/calendar';
import VideoElement from "../VideoElement";

function UpdateEventDetail() {
    const { id } = useParams(); // Get the project ID from the URL
    const navigate = useNavigate();
    const [event, setEvent] = useState({ title:'', description:''});
    const [featuredImage, setFeaturedImage] = useState(null);
    const [newImages, setNewImages] = useState([]);

    const [removeImages, setRemoveImages] = useState([]);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState(''); 
    const fileInputRef = useRef(null);

    const featuredImageRef = useRef(null); 

    useEffect(() => {
        const fetchEventDetails = async () => {
            try {
                const response = await axios.get(`${config.BASE_URL}/api/admin/get-event/${id}`, { withCredentials: true });
                setEvent(response.data); // Set the fetched project details
            } catch (err) {
                setError('Failed to fetch location details');
            }
        };

        fetchEventDetails();
    }, [id]);

    const handleRemoveImage = async (imageName) => {
        try {
            const response = await axios.post(`${config.BASE_URL}/api/admin/remove-event-image`, { 
                eventId: event._id, 
                imageName 
            }, { withCredentials: true });
    
            if (response.status === 200) {
                // Remove image from the project state and track it for removal
                setRemoveImages(prev => [...prev, imageName]); // Add to removeImages array
                
                // Update project state
                setEvent(prevEvent => {
                    const updatedImages = prevEvent.galleryImages.filter(image => image !== imageName);
                    return {
                        ...prevEvent,
                        galleryImages: updatedImages,
                        featuredImage: prevEvent.featuredImage === imageName ? null : prevEvent.featuredImage,
                    };
                });
            } else {
                console.error('Failed to remove image');
            }
        } catch (err) {
            console.error('Error removing image:', err);
        }
    };

    const handleFeaturedImageChange = (e) => {
        setFeaturedImage(e.target.files[0]); // Set the featured image
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('title', event.title);
        formData.append('description', event.description);

        // Add the featured image if selected
        if (featuredImage) {
            formData.append('featuredImage', featuredImage);
        }

        for (const image of newImages) {
            formData.append('galleryImages', image);
        }


        try {
            const response = await axios.put(`${config.BASE_URL}/api/admin/update-event/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            });

            setEvent(response.data.event);

            if (featuredImageRef.current) {
                featuredImageRef.current.value = '';
            }

            setFeaturedImage(null);
            setSuccessMessage('Event updated successfully!'); 
        } catch (err) {
            setError('Failed to update event');
        }
    };

    const renderImageOrVideo = (src) => {
        const extension = src.split(".").pop();
        var mediaType = "";
        const videoExtensions = [
            "mp4",
            "3gp",
            "avi",
            "mkv",
            "webm",
            "mov",
            "flv",
            "wmv",
            "mpeg",
            "mpg",
            "m4v",
          ];
          const imageExtensions = [
            "jpg",
            "jfif",
            "avif",
            "jpeg",
            "svg",
            "png",
            "ico",
            "gif",
            "bmp",
            "webp",
            "tiff",
            "heic",
          ];
    
          if (videoExtensions.includes(extension)) {
            mediaType = "VIDEO";
            
            return (
              <VideoElement url={src}/>
            );
          } else if (imageExtensions.includes(extension)) {
            mediaType = "IMAGE";
            
            return (
              <img src={src} alt="gallery" />
            );
          }
      }
      const handleNewImageChange = (e) => {
        setNewImages(e.target.files); // Set new images to be uploaded
    };



    return (
        <div className="dashboard update_detail_wrap">
            <div className="container">
                <h1 className="dash_title">Edit Event</h1>
                <div className="back_btn_wrap">
                    <button className="back-btn" onClick={() => navigate(-1)}>Go Back</button>
                </div>
                {event ? (
                    <form onSubmit={handleFormSubmit} encType="multipart/form-data">
                        <div className="update_details_wrap">
                            <div className="form-group">
                                <label>Event Title</label>
                                <input
                                    type="text"
                                    value={event.title}
                                    onChange={(e) => setEvent({ ...event, title: e.target.value })}
                                />
                            </div>

                            <div className="form-group textareaField">
                                <h2>Description</h2>
                                <textarea
                                    name="description"
                                    value={event.description}
                                    onChange={(e) => setEvent({ ...event, description: e.target.value })}
                                    required
                                />
                            </div>
                                
                            
                            <div className="form-group">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        <div className="upload_item">
                                            <label>Featured Image</label>
                                            <input type="file" onChange={handleFeaturedImageChange} ref={featuredImageRef} />
                                        </div>

                                        <div className="detail_img_grid">
                                            {event.featuredImage ? (
                                                <div className="detail_img_item">
                                                    <div className="banner_img_wrap">
                                                    <img src={`${config.BASE_URL}${event.featuredImage}`} alt="Featured" />
                                                    </div>
                                                    <button className="remove_btn" type="button" onClick={() => handleRemoveImage(event.featuredImage)}>Remove</button>
                                                </div>
                                            ) : (
                                                <p>No image available.</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                            
                            <div className="form-group">
                                <label>Upload Gallery Images</label>
                                <input type="file" multiple onChange={handleNewImageChange} ref={fileInputRef} />
                            </div>
                            <div className="detail_item_inner gallery_inner">
                            <div className="detail_img_grid">
                                {event?.galleryImages && event.galleryImages.map((image, index) => (
                                    <div className="detail_image_item" key={index}>
                                        <div className="detail_img_item">
                                        {/* <img src={`${config.BASE_URL}${image}`} alt={`Project ${index}`} /> */}
                                        {renderImageOrVideo(`${config.BASE_URL}${image}`)}

                                        </div>
                                        <button className="remove_btn" type="button" onClick={() => handleRemoveImage(image)}>Remove</button>
                                    </div>
                                ))}
                            </div>
                            </div>
                        </div>
                    
                            <div className="update_btn_wrap">
                                <button className="update_btn" type="submit">Update Event</button>
                            </div>

                            {successMessage && <p className="success-message">{successMessage}</p>}

                            {error && <p className="error-message">{error}</p>}
                        
                        </div>
                    </form>
                ) : (
                    <h5>Event not found!</h5>
                )}
            </div>
        </div>
    ); 
}

export default UpdateEventDetail;
