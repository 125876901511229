import React, { useEffect, useState } from 'react';
import upBlackarrow from '../assets/images/up-blackarrow.svg';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import config from '../config';

function Modal({ show, handleClose, menubg, title, time, time1, moreLink, moreText="More", menuItems, menuId }) {
  useEffect(() => {
    if (show) {
      document.documentElement.classList.add('modal-open');
    } else {
      document.documentElement.classList.remove('modal-open');
    }
    
    return () => {
      document.documentElement.classList.remove('modal-open');
    };
  }, [show]);

  const [activeIndex, setActiveIndex] = useState(null);
  const [activeTab, setActiveTab] = useState('food');
  const [selectedMenuItem, setSelectedMenuItem] = useState('');
  const [menu, setmenu] = useState(null);
  const [types, setTypes] = useState([]);

  const menuData = {
    food: [
      { title: 'SOMETHING LIGHT', 
        description: 'A light meal option.', 
        items: [
          { name: 'Smashed Avocado on Sourdough Rye', price: '10', description: 'with Saltbush Dukkah, Feta & Lemon' },
          { name: 'Turkish Eggs with Roasted Tomatoes & Labneh', price: '12', description: 'with Sourdough, Fresh Herbs, Aleppo pepper & Two Poached Eggs' },
          { name: 'Smashed Avocado on Sourdough Rye', price: '10', description: 'with Two Poached Eggs, Slow Cooked Grass Fed Beef, Spinach, Avocado, Sumac Onions, Coriander & Apple Cider Hollandaise' },
          { name: 'Smashed Avocado on Sourdough Rye', price: '10', description: 'with Two Poached Eggs, Slow Cooked Grass Fed Beef, Spinach, Avocado, Sumac Onions, Coriander & Apple Cider Hollandaise' },
          { name: 'Smashed Avocado on Sourdough Rye', price: '10', description: 'with Two Poached Eggs, Slow Cooked Grass Fed Beef, Spinach, Avocado, Sumac Onions, Coriander & Apple Cider Hollandaise' },
          { name: 'Smashed Avocado on Sourdough Rye', price: '10', description: 'with Two Poached Eggs, Slow Cooked Grass Fed Beef, Spinach, Avocado, Sumac Onions, Coriander & Apple Cider Hollandaise' },
          { name: 'Smashed Avocado on Sourdough Rye', price: '10', description: 'with Two Poached Eggs, Slow Cooked Grass Fed Beef, Spinach, Avocado, Sumac Onions, Coriander & Apple Cider Hollandaise' }
        ] 
      },
      { title: 'BREAKFAST BOWLS', 
        description: 'Delicious breakfast bowls.', 
        items: [
          { name: 'Bowl A', price: '8', description: 'A healthy bowl packed with nutrients.' }, 
          { name: 'Bowl B', price: '9', description: 'A delicious mix of flavors.' } 
        ] 
      },
      { title: 'BRUNCH', 
        description: 'Brunch options.', 
        items: [
          { name: 'Dish A', price: '15', description: 'A hearty brunch option.' }, 
          { name: 'Dish B', price: '14', description: 'A light and refreshing dish.' } 
        ] 
      },
    ],
    drink: [
      { title: 'SOMETHING Drink', 
        description: 'Refreshing drink options.', 
        items: [
          { name: 'Drink A', price: '5', description: 'A cool beverage to quench your thirst.' }, 
          { name: 'Drink B', price: '6', description: 'A fruity drink for a sunny day.' } 
        ] 
      },
      { title: 'BREAKFAST BOWLS', 
        description: 'Beverage bowls for breakfast.', 
        items: [
          { name: 'Drink Bowl A', price: '4', description: 'A bowl of refreshing drink.' }, 
          { name: 'Drink Bowl B', price: '5', description: 'A healthy beverage option.' } 
        ] 
      },
      { title: 'BRUNCH', 
        description: 'Brunch drinks.', 
        items: [
          { name: 'Drink Brunch A', price: '7', description: 'A special drink for brunch.' }, 
          { name: 'Drink Brunch B', price: '8', description: 'A cocktail to enjoy.' } 
        ] 
      },
    ],
  };

  const toggleMenuTypeTab = () => {
    if (activeTab === 'food') {
      if (types.length > 0) {
        const found = types.some(type => {
          if (menu["food"][type]) {
            setSelectedMenuItem(type);
            return true; // Exit loop when a match is found
          }
          return false;
        });
        if (!found) setSelectedMenuItem("");
      } else {
        setSelectedMenuItem("");
      }
    } else if (activeTab === 'drink') {
      if (types.length > 0) {
        const found = types.some(type => {
          if (menu["drink"][type]) {
            setSelectedMenuItem(type);
            return true; // Exit loop when a match is found
          }
          return false;
        });
        if (!found) setSelectedMenuItem("");
      } else {
        setSelectedMenuItem("");
      }
    }
  }

  useEffect(() => {
    toggleMenuTypeTab();
  }, [activeTab]);
  

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleMenuItemClick = (item) => {
    setSelectedMenuItem(item); 
  };

  const fetchLocationDetails = async () => {
    try {
        const response = await axios.get(`${config.BASE_URL}/api/admin/get-menu-items/${menuId}`, { withCredentials: true });
        setmenu(response.data.data);
        setTypes(response.data.types);
    } catch (err) {
        console.log('Failed to fetch location details');
    }
  };

  useEffect(() => {
    fetchLocationDetails();
  }, [menuId]);

  useEffect(() => {
    toggleMenuTypeTab();
  }, [types]);

  return (
    <div className={`modal ${show ? 'show' : ''}`} onClick={handleClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close" onClick={handleClose}>&times;</span>
        <div className='menu-modal-content'>
          <div className='menu-modal-content-top'>
            <div className='menu-modal-img desktop-view' style={{ backgroundImage: `url(${menubg})` }}>
              <h2 className='text-white'>{title}</h2>
              <div className='menu-modal-img-botm d-flex justify-content-between'>
                <div className="status-sec">
                  <span className='menu-time'>{time} <span>{time1}</span></span>
                </div>
                <NavLink className="more-icon" exact to={moreLink}>{moreText}<img src={upBlackarrow} alt="arrow-icon"/></NavLink>
              </div>
            </div>
          </div>
          <div className='menu-modal-content-botm d-flex'>
            <div className='menu-modal-content-botm-left'>
              <ul className='tab-title'>
                <li className={activeTab === 'food' ? 'active-tab' : ''} onClick={() => handleTabClick('food')}>
                  <span>FOOD</span>
                </li>
                <li className={activeTab === 'drink' ? 'active-tab' : ''} onClick={() => handleTabClick('drink')}>
                  <span>DRINKS</span>
                </li>
              </ul>

              <div className='tab-content'>
                {activeTab === 'food' && (
                  <div className='tab-content-panel'>
                    <ul className='menu-list'>

                      {types.length > 0 ? types.map(type => (
                        menu["food"][type] && (
                          // menu['food'][type].map((item, key) => (
                            <li 
                              className={`position-relative ${selectedMenuItem === type ? 'active' : ''}`}
                              key={type}
                              onClick={() => handleMenuItemClick(type)}
                            >
                              {type}
                            </li>
                          // ))
                        )
                    )) : (
                      <li 
                        className={`position-relative`}
                      >
                        No Items found
                      </li>
                    )}
                    </ul>
                  </div>
                )}

                {activeTab === 'drink' && (
                  <div className='tab-content-panel'>
                    <ul className='menu-list'>

                      {types.length > 0 ? types.map(type => (
                        menu["drink"][type] && (
                          // menu['food'][type].map((item, key) => (
                            <li 
                              className={`position-relative ${selectedMenuItem === type ? 'active' : ''}`}
                              key={type}
                              onClick={() => handleMenuItemClick(type)}
                            >
                              {type}
                            </li>
                          // ))
                        )
                    )) : (
                      <li 
                        className={`position-relative`}
                        
                      >
                        No Items found
                      </li>
                    )}
                    </ul>
                  </div>
                )}
                
              </div>
            </div>

            <div className='menu-modal-content-botm-right'>
              {activeTab === 'food' && (
                types.length > 0 && types.map(type => (
                  (menu["food"][type] && type === selectedMenuItem) &&  (
                    <div className='menu-items-detail' key={type}>
                        <h5>{type}</h5>
                        <ul className='submenu'>
                          {menu['food'][type].map((item, index) => (
                            <li key={index}>
                              <div className='d-flex justify-content-between'>
                                <span className='menu-items'>
                                  <h4>{item.name}</h4>
                                  <p>{item.description}</p>
                                </span>
                                <span className='quantity'>{item.price}</span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                  )
                ))
              )}

              {activeTab === 'drink' && (
                types.length > 0 && types.map(type => (
                  (menu["drink"][type] && type === selectedMenuItem) &&  (
                    <div className='menu-items-detail' key={type}>
                        <h5>{type}</h5>
                        <ul className='submenu'>
                          {menu['drink'][type].map((item, index) => (
                            <li key={index}>
                              <div className='d-flex justify-content-between'>
                                <span className='menu-items'>
                                  <h4>{item.name}</h4>
                                  <p>{item.description}</p>
                                </span>
                                <span className='quantity'>{item.price}</span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                  )
                ))
              )}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
