import React, { useState, useEffect } from "react";
import "../Header/header.css";
import logourl from "../../assets/images/LittleCove.svg";
import logowhiteurl from "../../assets/images/white-main-logo.svg";

import darklogourl from "../../assets/images/dark-logo.svg";
import menuIcon from "../../assets/images/menu-icon.svg";
import closeIcon from "../../assets/images/close-icon.png"; // Close icon for when the menu is open
import menurightImage from "../../assets/images/header-menu-right.jpg";
import { NavLink, Link } from 'react-router-dom';

const menuItems = [
  { text: 'Locations', link: '/locations' },
  { text: 'For Events', link: '/partner-with-us' },
  { text: 'About Us', link: '/about' },
  { text: 'Buy Coffee', link: '/' },
  { text: 'Contact Us', link: '/contact' },
];

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  // Add or remove 'menu-open' class on the <html> element
  useEffect(() => {
    if (isMenuOpen) {
      document.documentElement.classList.add('menu-open');
    } else {
      document.documentElement.classList.remove('menu-open');
    }
  }, [isMenuOpen]);

  return (
    <>
      <div className="header">
        <div className="container">
          <div className="header-main">
            <nav className="navbar navbar-expand-lg">
              <NavLink to="/" className="header-logo">
                <img src={logowhiteurl} alt="header-logo" />
              </NavLink>
              <Link className="navbar-toggler text-white" to="/" onClick={toggleMenu}>
                <span className="navbar-toggler-icon">
                  {isMenuOpen ? (
                    <img src={closeIcon} alt="close-icon" /> // Show close icon when menu is open
                  ) : (
                    <img src={menuIcon} alt="menu-icon" /> // Show menu icon when menu is closed
                  )}
                </span>
              </Link>
              <div className={`header-navbar ${isMenuOpen ? 'show' : ''}`}>
                <div className="header-navbar-inner">
                  <div className="header-navbar-left">
                    <NavLink to="/" className="header-logo">
                      <img src={darklogourl} alt="header-logo" />
                    </NavLink>
                    <ul>
                      {menuItems.map((item, index) => (
                        <li key={index}>
                          <Link to={item.link} onClick={closeMenu}>
                            {item.text}
                            {item.text === 'Buy Coffee' && <span className="extra-span"> - coming soon</span>}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="header-navbar-right">
                      <img src={menurightImage}   alt="Image" />
                  </div>
                  <Link className="navbar-toggler text-white closemenu" to="/" onClick={toggleMenu}>
                    <span className="navbar-toggler-icon">
                      {isMenuOpen ? (
                        <img src={closeIcon} alt="close-icon" /> // Show close icon when menu is open
                      ) : (
                        <img src={menuIcon} alt="menu-icon" /> // Show menu icon when menu is closed
                      )}
                    </span>
                  </Link>
                </div>
                

              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar;
