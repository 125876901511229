import React, { useEffect, useState } from "react";
import axios from "axios"; 
import { useParams, useNavigate, Link } from "react-router-dom";
import config from '../../config';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { TabView, TabPanel } from 'primereact/tabview';

function MenuDetails() {
    const { id } = useParams(); // Get the project ID from the URL
    const [menu, setmenu] = useState(null);
    const [types, setTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [addItemType, setAddItemType] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        title: '',
        startTime: '',
        endTime: '',
        featuredImage: null,
        galleryImages: null,
        bannerImage: null,
        address: ''
    });

    const fetchProjectDetails = async () => {
        try {
            const response = await axios.get(`${config.BASE_URL}/api/admin/get-menu-items/${id}`, { withCredentials: true });
            setmenu(response.data.data);
            setTypes(response.data.types);
        } catch (err) {
            setError('Failed to fetch location details');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProjectDetails();
    }, [id]);

    const handleDelete = async (itemId) => {
        try {
            await axios.delete(`${config.BASE_URL}/api/admin/delete-menu-item/${itemId}`, { withCredentials: true });
            // Navigate back to the project list or show a success message
            // navigate(`/admin/menu`);
            fetchProjectDetails(); 
        } catch (err) {
            setError('Failed to delete the location');
        }
    };

    const handleInputChange = (e) => {
        const { name, value, files } = e;
        setFormData((prevData) => ({
        ...prevData,
        [name]: files ? files : value // Set files if they exist, otherwise set value
        }));
    };

    const handleAddItem = async (e, food) => {
        e.preventDefault();
        const data = new FormData();
        console.log("Submit initialized");
        console.log("foodType: ", food);

        if(food === "food"){
            data.append('name', formData.foodName);
            data.append('type', formData.foodType);
            data.append('menuId', id);
            data.append('shortDescription', formData.foodShortDescription);
            data.append('price', formData.foodPrice);
            data.append('foodType', food);
        }else{
            data.append('name', formData.drinkName);
            data.append('type', formData.drinkType);
            data.append('menuId', id);
            data.append('shortDescription', formData.drinkShortDescription);
            data.append('price', formData.drinkPrice);
            data.append('foodType', food);
        }
        

        try {
            const response = await axios.post(`${config.BASE_URL}/api/admin/add-menu-item`, data, {
                withCredentials: true,
                headers: {
                'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                console.log("Got success response");
                const token = localStorage.getItem('token');
                if (token) {
                // navigate('/admin/dashboard');
                setFormData({
                    foodName: '',
                    foodType: '',
                    foodShortDescription: '',
                    foodPrice: '',
                    drinkName: '',
                    drinkType: '',
                    drinkShortDescription: '',
                    drinkPrice: ''
                });


                fetchProjectDetails();
                console.log("menus fetched");

                setSuccessMessage("Menu items updated...");

                setTimeout(() => {
                    setSuccessMessage("");
                }, 4000);

                } else {
                    navigate('/admin/login');
                }
            }
        } catch (err) {
            console.log("Error occured while saving menus");
            console.log("err: ", err);
            setError(err.response?.data?.message || 'Failed to add Menu item');
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;


    return (
        <div className="dashboard project_detail_wrap">
            <div className="container">
                <h1 className="dash_title">Dashboard</h1>
                <div className="back_btn_wrap">
                    <Link to={`/admin/add-menu-item/${id}`} className="back-btn">Add Menu Item</Link>
                    {'  '}
                    <button className="back-btn" onClick={() => navigate(-1)}>Go Back</button>
                </div>
                <div className="main-section">
                    <div className="projects">
                        <div className="projects-inner">
                                <h2>Menu Items</h2>

                                <TabView>
                                    <TabPanel header="Food">
                                        <Accordion>
                                            {types.length > 0 && types.map(type => (
                                                menu['food'][type] && (
                                                    <AccordionTab header={type}>
                                                    
                                                        {menu['food'][type].map((item, key) => (
                                                            <div className="singleMenuItem">
                                                                <h3>{item.name}</h3>
                                                                <p>Description: {item.shortDescription}</p>
                                                                <p>Price: ${item.price}</p>
        
                                                                <br />
                                                                
                                                                <Link className="view_detail actionMenuBtn" to={`/admin/update-menu/${item._id}`}>Edit</Link> 
                                                                {' '}
                                                                <button className="view_detail actionMenuBtn" onClick={() => handleDelete(item._id)}>Delete</button>
                                                            </div>
                                                        ))}
        
                                                    </AccordionTab>
                                                )
                                            ))}
                                        </Accordion>
                                        {addItemType === "food" && ( <>
                                            <form onSubmit={(e) => handleAddItem(e, "food")}>
                                                <div className="form-login addMenuForm">
                                                    <div className="add_field">
                                                        <h2>Name</h2>
                                                        <input
                                                        type="text"
                                                        name="foodName"
                                                        value={formData.foodName}
                                                        onChange={(e) => handleInputChange(e.target)}
                                                        required
                                                        />
                                                    </div>
                                                    <div className="add_field">
                                                        <h2>Type</h2>
                                                        <input
                                                        type="text"
                                                        name="foodType"
                                                        value={formData.foodType}
                                                        onChange={(e) => handleInputChange(e.target)}
                                                        required
                                                        />
                                                    </div>
                                                    <div className="add_field">
                                                        <h2>Description</h2>
                                                        <input
                                                        type="text"
                                                        name="foodShortDescription"
                                                        value={formData.foodShortDescription}
                                                        onChange={(e) => handleInputChange(e.target)}
                                                        required
                                                        />
                                                    </div>
                                    
                                                    <div className="add_field">
                                                        <h2>Price</h2>
                                                        <input
                                                        type="text"
                                                        name="foodPrice"
                                                        value={formData.foodPrice}
                                                        onChange={(e) => handleInputChange(e.target)}
                                                        required
                                                        />
                                                    </div>
                                                    
                                                </div>

                                                <div className="add_menuBtn">
                                                    <button type="submit">Submit</button>
                                                </div>
                                            </form>
                                        </> )}
                                        
                                        <button onClick={() => setAddItemType("food")}>Add Food</button>
                                    </TabPanel>
                                    <TabPanel header="Drinks">
                                        <p className="m-0">
                                        <Accordion>
                                            {types.length > 0 && types.map(type => (
                                                menu["drink"][type] && (
                                                    <AccordionTab header={type}>
                                                    
                                                        {menu['drink'][type].map((item, key) => (
                                                            <div className="singleMenuItem">
                                                                <h3>{item.name}</h3>
                                                                <p>Description: {item.shortDescription}</p>
                                                                <p>Price: ${item.price}</p>
        
                                                                <br />
                                                                
                                                                <Link className="view_detail actionMenuBtn" to={`/admin/update-menu/${item._id}`}>Edit</Link> 
                                                                {' '}
                                                                <button className="view_detail actionMenuBtn" onClick={() => handleDelete(item._id)}>Delete</button>
                                                            </div>
                                                        ))}
        
                                                    </AccordionTab>
                                                )
                                            ))}
                                        </Accordion>
                                        {addItemType === "drink" && (
                                            <form onSubmit={(e) => handleAddItem(e, "drink")}>
                                                <div className="form-login addMenuForm">
                                                    <div className="add_field">
                                                        <h2>Name</h2>
                                                        <input
                                                        type="text"
                                                        name="drinkName"
                                                        value={formData.drinkName}
                                                        onChange={(e) => handleInputChange(e.target)}
                                                        required
                                                        />
                                                    </div>
                                                    <div className="add_field">
                                                        <h2>Type</h2>
                                                        <input
                                                        type="text"
                                                        name="drinkType"
                                                        value={formData.drinkType}
                                                        onChange={(e) => handleInputChange(e.target)}
                                                        required
                                                        />
                                                    </div>
                                                    <div className="add_field">
                                                        <h2>Description</h2>
                                                        <input
                                                        type="text"
                                                        name="drinkShortDescription"
                                                        value={formData.drinkShortDescription}
                                                        onChange={(e) => handleInputChange(e.target)}
                                                        required
                                                        />
                                                    </div>
                                    
                                                    <div className="add_field">
                                                        <h2>Price</h2>
                                                        <input
                                                            type="text"
                                                            name="drinkPrice"
                                                            value={formData.drinkPrice}
                                                            onChange={(e) => handleInputChange(e.target)}
                                                            required
                                                        />
                                                    </div>
                                                    
                                                </div>
                                                
                                                <div className="add_menuBtn">
                                                    <button type="submit">Submit</button>
                                                </div>
                                            </form>
                                        )}

                                        <button onClick={() => setAddItemType("drink")}>Add Drink</button>
                                        </p>
                                    </TabPanel>
                                </TabView>
                                {/* <Accordion>
                                    {types.length > 0 && types.map(type => (
                                        menu[type].length > 0 && (
                                            <AccordionTab header={type}>
                                            
                                                {menu[type].map((item, key) => (
                                                    <div className="singleMenuItem">
                                                        <h3>{item.name}</h3>
                                                        <p>Description: {item.shortDescription}</p>
                                                        <p>Quantity: {item.quantity}</p>
                                                        <p>Price: ${item.price}</p>

                                                        <br />
                                                        
                                                        <Link className="view_detail actionMenuBtn" to={`/admin/update-menu/${item._id}`}>Edit</Link> 
                                                        {' '}
                                                        <button className="view_detail actionMenuBtn" onClick={() => handleDelete(item._id)}>Delete</button>
                                                    </div>
                                                ))}

                                            </AccordionTab>
                                        )
                                    ))}
                                </Accordion> */}
                            {/* </>
                            ) : (
                                <p className="dataNotFound">No menu items found!</p>
                            )} */}
                        </div>

                        <p style={{
                            color: "#fff", 
                            fontWeight: 500,
                            textAlign: "center",
                            fontSize: "18px",
                            letterSpacing: "1.2px"
                        }}>{successMessage ? successMessage : ""}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MenuDetails;
