import React, { useEffect, useRef } from 'react';
import slider1 from '../assets/images/eventslider1.jpg';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import config from '../config';
gsap.registerPlugin(ScrollTrigger);

function PartnerGalleryTop({events}) {
const isImage = (src) => {
    return /\.(jpg|jpeg|png|gif)$/.test(src); // You can add more extensions if needed
    };
  const pinContainerRef = useRef(null);  
  useEffect(() => {
    let ctx = gsap.context(() => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: pinContainerRef.current,
          start: "top +=400",
          end: "+=1000",
          scrub: true,
          markers: false,
        }
      });

      tl.to(".gallery_image_1", {
        duration: 1,
        x: 40,
        y: -50,
      }, 0);     
      tl.to(".gallery_image_2", {
        duration: 1,
        x: 40,
        y: -180,
      }, 0);
      tl.to(".gallery_image_3", {
        duration: 1,
        x: 0,
        y: -380,
      }, 0);
    });

    return () => {
      ctx.revert();
    };
  }, []);

  return (
    <div className='partner_gallery_block overflow-hidden'>     
      <div className="partner_gallery_wrapper position-relative pin-container" ref={pinContainerRef}>
        <div className='d-flex nowrap'>
          {events.map((event, index) => ( <>
            {index < 3 && (

              <div className={`${index === 0 ? "partner_gallery_top" : ""} ${index === 1 ? "partner_gallery_middle" : ""} ${index === 2 ? "partner_gallery_right" : ""}`}>
                  <div className={`partner_gallery_image position-relative gallery_image_${index+1} z-2`}>
                      {isImage(event.featuredImage) && (
                          <img src={`${config.BASE_URL}${event.featuredImage}`} alt="Gallery" className="w-100 position-absolute" />
                      )}

                      {
                      //  : (
                      //     <video src={event.featuredImage} className="w-100 position-absolute" autoPlay muted loop />
                      // )
                      }

                    {index !== 2 && (
                      <div className='position-absolute gallery-botm-desc d-flex text-white z-2'>
                          <h2>{event.title}</h2>
                          <p className='text-white'>{event.description}</p>
                      </div>
                    )}
                  </div>
              </div>
            )}   
            {/* <div className='partner_gallery_middle '>
                <div className='partner_gallery_image position-relative gallery_image_2'>
                    {isImage(slider1) ? (
                        <img src={slider1} alt="Gallery" className="w-100 position-absolute" />
                    ) : (
                        <video src={slider1} className="w-100 position-absolute" autoPlay muted loop />
                    )}
                    <div className='position-absolute gallery-botm-desc d-flex text-white z-2'>
                        <h2>Brand event</h2>
                        <p className='text-white'>Work with LCE to create a custom experience or private event. </p>
                    </div>
                </div>
            </div>
            <div className='partner_gallery_right'>
                <div className='partner_gallery_image position-relative gallery_image_3'>
                    {isImage(slider1) ? (
                        <img src={slider1} alt="Gallery" className="w-100 position-absolute" />
                    ) : (
                        <video src={slider1} className="w-100 position-absolute" autoPlay muted loop />
                    )}
                </div>
            </div> */}
          </> ))}
        </div>
      </div>
    </div>
  );
}

export default PartnerGalleryTop;



function PartnerGalleryBottom({events}) {
    const isImage = (src) => {
        return /\.(jpg|jpeg|png|gif)$/.test(src); // You can add more extensions if needed
        };
      const pinContainerRef = useRef(null);  
      useEffect(() => {
        let ctx = gsap.context(() => {
          let tl = gsap.timeline({
            scrollTrigger: {
              trigger: pinContainerRef.current,
              start: "top +=1400",
              end: "+=2000",
              scrub: true,
              markers: false,
            }
          });
    
          tl.to(".gallery_image_11", {
            duration: 1,
            x: -50,
            y: -200,
          }, 0);     
          tl.to(".gallery_image_12", {
            duration: 1,
            x: -40,
            y: -180,
          }, 0);
          tl.to(".gallery_image_13", {
            duration: 1,
            x: 50,
            y: -480,
          }, 0);
        });
    
        return () => {
          ctx.revert();
        };
      }, []);
    
      return (
        <div className='partner_gallery_block partner_gallery_block_botm  overflow-hidden'>     
          <div className="partner_gallery_wrapper position-relative pin-container" ref={pinContainerRef}>
            <div className='d-flex nowrap'>
                
            {/* <div className='partner_gallery_right'>
                <div className='partner_gallery_image position-relative gallery_image_13'>
                    {isImage(slider1) ? (
                        <img src={slider1} alt="Gallery" className="w-100 position-absolute" />
                    ) : (
                        <video src={slider1} className="w-100 position-absolute" autoPlay muted loop />
                    )}
                </div>
            </div>
            <div className='partner_gallery_middle '>
                <div className='partner_gallery_image position-relative gallery_image_12'>
                    {isImage(slider1) ? (
                        <img src={slider1} alt="Gallery" className="w-100 position-absolute" />
                    ) : (
                        <video src={slider1} className="w-100 position-absolute" autoPlay muted loop />
                    )}
                    <div className='position-absolute gallery-botm-desc d-flex text-white z-2'>
                        <h2>Brand event</h2>
                        <p className='text-white'>Work with LCE to create a custom experience or private event. </p>
                    </div>
                </div>
            </div>
            <div className='partner_gallery_top'>
                <div className='partner_gallery_image position-relative gallery_image_11 z-2'>
                    {isImage(slider1) ? (
                        <img src={slider1} alt="Gallery" className="w-100 position-absolute" />
                    ) : (
                        <video src={slider1} className="w-100 position-absolute" autoPlay muted loop />
                    )}
                    <div className='position-absolute gallery-botm-desc d-flex text-white z-2'>
                        <h2>Brand event</h2>
                        <p className='text-white'>Work with LCE to create a custom experience or private event. </p>
                    </div>
                </div>
            </div> */}

            {events.map((event, index) => ( <>
              {index < 3 && (

                <div className={`${index === 0 ? "partner_gallery_right" : ""}${index === 1 ? "partner_gallery_middle" : ""}${index === 2 ? "partner_gallery_top" : ""}`}>
                    <div className={`partner_gallery_image position-relative gallery_image_${Math.abs(index-13)}`}>
                        {isImage(event.featuredImage) && (
                            <img src={`${config.BASE_URL}${event.featuredImage}`} alt="Gallery" className="w-100 position-absolute" />
                        )}
                        {index !== 0 && (
                          <div className='position-absolute gallery-botm-desc d-flex text-white z-2'>
                              <h2>{event.title}</h2>
                              <p className='text-white'>{event.description}</p>
                          </div>
                        )}
                    </div>
                </div>
              )}
            </> ))}
            </div>
          </div>
        </div>
      );
    }
    
    export {PartnerGalleryBottom};
