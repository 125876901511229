import React, { useEffect, useRef } from 'react';
import Gallery1 from '../assets/images/about-gallery1.png';
import Gallery2 from '../assets/images/gallery2.mp4';
import Gallery3 from '../assets/images/gallery3.mp4';
import Gallery4 from '../assets/images/aboutgallery4.png';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

function AboutGallery() {
const isImage = (src) => {
    return /\.(jpg|jpeg|png|gif)$/.test(src); // You can add more extensions if needed
    };
  const pinContainerRef = useRef(null);  
  useEffect(() => {
    let ctx = gsap.context(() => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: pinContainerRef.current,
          start: "top +=300",
          end: "+=1000",
          scrub: true,
          markers: false,
        }
      });

      tl.to(".gallery_image_1", {
        duration: 1,
        x: 0,
        y: -200,
      }, 0);     
      tl.to(".gallery_image_2", {
        duration: 1,
        x: -80,
        y: 0,
      }, 0);
      tl.to(".gallery_image_3", {
        duration: 1,
        x: 80,
        y: 0,
      }, 0);
      tl.to(".gallery_image_4", {
        duration: 1,
        x: 0,
        y: -110,
      }, 0);
    });

    return () => {
      ctx.revert();
    };
  }, []);

  return (
    <div className='home_gallery_block about_gallery_block overflow-hidden d-flex'> 
      <div className="home-gallery-left">
        <p>Little Cove Espresso Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      </div>     
      <div className="home_gallery_wrapper about-gallery position-relative pin-container" ref={pinContainerRef}>
        <div className='home_gallery_top'>
            <div className='home_gallery_image position-relative gallery_image_1 z-2'>
            {isImage(Gallery2) ? (
                <img src={Gallery2} alt="Gallery" className="w-100 position-absolute" />
            ) : (
                <video src={Gallery2} className="w-100 position-absolute" autoPlay muted loop />
            )}
            <div className='position-absolute gallery-botm-desc d-flex text-white'><h3>14% coffee stat here</h3><h6>source</h6></div>
            </div>
        </div>
        <div className='home_gallery_middle d-flex justify-content-between nowrap'>
            <div className='home_gallery_image position-relative gallery_image_2'>
            {isImage(Gallery1) ? (
                <img src={Gallery1} alt="Gallery" className="w-100 position-absolute" />
            ) : (
                <video src={Gallery1} className="w-100 position-absolute" autoPlay muted loop />
            )}
            <div className='position-absolute gallery-botm-desc d-flex text-black'><h3>14% coffee stat here</h3><h6>source</h6></div>
            </div>
            <div className='home_gallery_image position-relative gallery_image_3'>
            {isImage(Gallery4) ? (
                <img src={Gallery4} alt="Gallery" className="w-100 position-absolute" />
            ) : (
                <video src={Gallery4} className="w-100 position-absolute" autoPlay muted loop />
            )}
            </div>
        </div>
        <div className='home_gallery_bottom'>
            <div className='home_gallery_image position-relative gallery_image_4'>
            {isImage(Gallery3) ? (
                <img src={Gallery3} alt="Gallery" className="w-100 position-absolute" />
            ) : (
                <video src={Gallery3} className="w-100 position-absolute" autoPlay muted loop />
            )}
            </div>
        </div>
      </div>
    </div>
  );
}

export default AboutGallery;
