import React from "react";
import DashboardHeader from '../../components/admincomponents/DashboardHeader';
import UpdateEventDetail from '../../components/admincomponents/UpdateEventDetail';

function UpdateEvent({onLogout}) {
  return (
    <>
      <DashboardHeader onLogout={onLogout}/>
      <UpdateEventDetail />            
    </>
  );
}

export default UpdateEvent;
