import React from "react";
import DashboardHeader from '../../components/admincomponents/DashboardHeader';
import LocationsList from '../../components/admincomponents/LocationsList';

function Locations({onLogout}) {
  return (
    <>
      <DashboardHeader onLogout={onLogout}/>
      <LocationsList  />            
    </>
  );
}

export default Locations;
