import React from "react";
import DashboardHeader from '../../components/admincomponents/DashboardHeader';
import EventsList from '../../components/admincomponents/EventsList';

function AdminEvents({onLogout}) {
  return (
    <>
      <DashboardHeader onLogout={onLogout}/>
      <EventsList  />            
    </>
  );
}

export default AdminEvents;
