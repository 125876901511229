import React from "react";
import DashboardHeader from '../../components/admincomponents/DashboardHeader';
import AddNewEvent from '../../components/admincomponents/AddEvent';

function AddEvent({onLogout}) {
  return (
    <>
      <DashboardHeader onLogout={onLogout}/>
      <AddNewEvent />            
    </>
  );
}

export default AddEvent;
