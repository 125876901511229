import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DashboardHeader from '../../components/admincomponents/DashboardHeader';
import LocationDetails from '../../components/admincomponents/LocationDetails';

function ViewLocation({onLogout}) {
  return (
    <>
      <DashboardHeader onLogout={onLogout}/>
      <LocationDetails />            
    </>
  );
}

export default ViewLocation;
