import React, {useState, useEffect }from "react";
import homeBanner from '../assets/images/home-banner-image.jpg';
import homeMobileBanner from '../assets/images/home-mobile-banner.jpg';
import ourStoryvideo from '../assets/images/our-story.mp4';
import Communityvideo from '../assets/images/community-video.mp4';
import upBlackarrow from '../assets/images/up-blackarrow.svg';
import whitelongArrow from '../assets/images/white-longarrow.svg';
import rightArrow from '../assets/images/blackright-arrow.svg';
import LocationSlider, { LocationMenu } from "../components/LocationSlider";
import { LocationData, LocationMenuData } from "../components/LocationData";
import Slider from "react-slick";
import {Link } from 'react-router-dom';
import HomeGallery from "../components/HomeGallery";
import MeetUs from "../components/MeetUs";
import HomeGallerySlider from "../components/HomeGallerySlider";
import HomeShortLargeImage from "../components/HomeShortLargeImage";

import axios from "axios";
import config from '../config';
import Modal from '../components/Modal';

import Layout from "./Layout";

export default function Home() {
    const [locations, setLocations] = useState([]);
    const [menus, setmenus] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});

    const TopmenuItems = [
        { name: 'COFFEE', link: '#' },
        { name: 'FOOD', link: '#' },
    ];
    const BotmmenuItems = [
        { name: 'MENU', link: '#' },
        { name: 'LOCATIONS', link: '/locations' },
    ];
    var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2.7,
        slidesToScroll: 1,
        centerMode: false,
        initialSlide: 0, 
        responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 2.6,
                slidesToScroll: 1,
                infinite: true,
                centerMode: false,
                arrows:false,
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2.7,
                slidesToScroll: 1,
                infinite: true,
                centerMode: false,
                arrows:false,
              }
            },
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2.6,
                slidesToScroll: 1,
                infinite: true,
                centerMode: false,
                arrows:false,
              }
            },
            {
              breakpoint: 767,
              settings: {
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 0, 
                centerMode: true,
              }
            },
            // {
            //     breakpoint: 480,
            //     settings: {
            //       slidesToShow: 1,
            //       slidesToScroll: 1,
            //       centerMode: true,
            //       arrows:false,
            //     }
            //   }
          ]  
    };

    const fetchLocations = async () => {
        try {
            const response = await axios.get(`${config.BASE_URL}/api/admin/get-locations`, { withCredentials: true });
            
            if(response.data){
                const menuItems = {}
                await Promise.all(response.data.map(async (item, key) => {
                    const menuData = await fetchMenuItems(item.menuId)
                    menuItems[item._id] = menuData
                }));
                
                setmenus(menuItems);
                setLocations(response.data);
            }

        } catch (err) {
            console.log('Failed to fetch locations');
        } 
    };

    const fetchMenuItems = async (menuId) => {
        try {
            const response = await axios.get(`${config.BASE_URL}/api/admin/get-menu-items-count/${menuId}`, { withCredentials: true });
            return response.data;
        } catch (err) {
            console.log('Failed to fetch menu items');
        }
    };

    useEffect(() => {
        fetchLocations();
    }, []);

    console.log("modalContent: ", modalContent);

    return (
        <>
        <Layout>
            <div className="home-banner text-white position-relative">
                <picture>
                    <source media="(max-width: 767px)" srcSet={homeMobileBanner} />
                    <img 
                        src={homeBanner} 
                        alt="Banner Image" 
                        className="position-absolute h-100 w-100 s-0 t-0 homebnnerbgimg" 
                    />
                </picture>                 
                <div className="container">
                   
                    <div className="home-banner-botmsec d-flex text-white">
                        <h1>Coffee is love.</h1>
                        <div className="home-banner-botmmenu">
                        <ul>
                            {BotmmenuItems.map((item, index) => (
                                <li key={index} className={index === 0 ? 'active' : ''}>
                                    <Link className="text-white" to={item.link}>{item.name}</Link>
                                <img src={rightArrow} alt="arrow-icon" />
                                </li>
                            ))}
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="community-sec">
                <div className="comunity-section-inner">
                    <div className="community-section-left">
                        <h2>Every community has their own style.</h2>
                        <p>explore our locations and their unique menus. </p>
                        {/* <div className="community-video">
                        <video width="100%" autoPlay muted loop className="">
                            <source src={Communityvideo} type="video/mp4" />
                        </video>
                        </div> */}
                    </div>
                    <div className="community-section-right">
                        <Slider {...settings}>
                        {
                            locations.map((val, index)=>{
                                return <LocationSlider 
                                    key={index}
                                    imgsrc={`${config.BASE_URL}${val.featuredImage}`}
                                    title={val.title}
                                    startTime={val.m_f_startTime}
                                    endTime={val.m_f_endTime}
                                    s_s_startTime={val.s_s_startTime}
                                    s_s_endTime={val.s_s_endTime}
                                    setShowModal={setShowModal}
                                    setModalContent={setModalContent}
                                    menuId={val.menuId}
                                    moreLink={`/single-location/${val.slug}`}
                                />
                            })
                        }
                        </Slider>
                        
                        {showModal && (

                            <Modal
                                show={showModal}
                                handleClose={() => setShowModal(false)}
                                title={modalContent.title}
                                content={modalContent.content}
                                menubg={modalContent.image}
                                time={modalContent.time}
                                time1={modalContent.time1}
                                moreLink={modalContent.moreLink}
                                menuItems={modalContent.menuItems}
                                menuId={modalContent.menuId}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="location-menu-sec">
                {/* <h6>LOCATIONS · HOURS · MENUS</h6> */}
                {
                    locations.map((val, index)=>{
                        return <LocationMenu 
                            key={index}
                            imgsrc={`${config.BASE_URL}${val.featuredImage}`}
                            title={val.title}
                            startTime={val.m_f_startTime}
                            endTime={val.m_f_endTime}
                            s_s_startTime={val.s_s_startTime}
                            s_s_endTime={val.s_s_endTime}
                            setShowModal={setShowModal}
                            setModalContent={setModalContent}
                            menuId={val.menuId}
                            moreLink={`/single-location/${val.slug}`}
                            hideImage={true}
                        />
                    })
                }
            </div>
            {/* <HomeGallery /> */}
            <MeetUs />
            <HomeShortLargeImage />
            <HomeGallerySlider /> 

            <div className="our-story position-relative">
            <video width="100%" autoPlay muted loop className="position-absolute">
                <source src={ourStoryvideo} type="video/mp4" />
            </video>
                <div className="container">
                    <div className="our-story-top-sec d-flex">
                        <h6>OUR STORY</h6>
                        <Link className="text-white" to="/about">MORE
                            <img src={upBlackarrow} alt="arrow" />
                        </Link>
                    </div>
                    <h2>from Little Cove </h2>
                    <div className="imgwithtext d-flex">
                        <img src={whitelongArrow} alt="arrow" />
                        <h2>you</h2>
                    </div>
                </div>

            </div>
            </Layout>
        </>
    );
}
