import React from "react";
import DashboardHeader from '../../components/admincomponents/DashboardHeader';
import AddMenu from '../../components/admincomponents/AddMenu';

function AddNewMenu({onLogout}) {
  return (
    <>
      <DashboardHeader onLogout={onLogout}/>
      <AddMenu />            
    </>
  );
}

export default AddNewMenu;
