import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../config";
import { Calendar } from 'primereact/calendar';

function AddMenu() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    startTime: '',
    endTime: '',
    featuredImage: null,
    galleryImages: null,
    bannerImage: null,
    address: ''
  });

  const handleInputChange = (e) => {
    const { name, value, files } = e;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files : value // Set files if they exist, otherwise set value
    }));
  };

  const handleAddItem = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('name', formData.name);
    data.append('type', formData.type);
    data.append('menuId', id);
    data.append('shortDescription', formData.shortDescription);
    data.append('quantity', formData.quantity);
    data.append('price', formData.price);

    try {
      const response = await axios.post(`${config.BASE_URL}/api/admin/add-menu-item`, data, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        const token = localStorage.getItem('token');
        if (token) {
          navigate('/admin/dashboard');
        } else {
          navigate('/admin/login');
        }
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to add Menu item');
    }
  };

  return (
    <div className="dashboard">
      <div className="container">
        <h1 className="dash_title">Dashboard</h1>
        <div className="main-section">
        <div className="back_btn_wrap">
          <button className="back-btn" onClick={() => navigate(-1)}>Go Back</button>
        </div>
          <div className="main-inner add_project_main">
            <form onSubmit={handleAddItem}>
              <div className="form-login">
              <div className="add_field">
                  <h2>Name</h2>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={(e) => handleInputChange(e.target)}
                    required
                  />
                </div>
                <div className="add_field">
                  <h2>Type</h2>
                  <input
                    type="text"
                    name="type"
                    value={formData.type}
                    onChange={(e) => handleInputChange(e.target)}
                    required
                  />
                </div>
                <div className="add_field">
                  <h2>Description</h2>
                  <input
                    type="text"
                    name="shortDescription"
                    value={formData.shortDescription}
                    onChange={(e) => handleInputChange(e.target)}
                    required
                  />
                </div>
                <div className="add_field">
                  <h2>Quantity</h2>
                  <input
                    type="text"
                    name="quantity"
                    value={formData.quantity}
                    onChange={(e) => handleInputChange(e.target)}
                    required
                  />
                </div>

                <div className="add_field">
                  <h2>Price</h2>
                  <input
                    type="text"
                    name="price"
                    value={formData.price}
                    onChange={(e) => handleInputChange(e.target)}
                    required
                  />
                </div>
                
              </div>
              <div className="add_project">
                <button type="submit">Add Item</button>
              </div>
            </form>
            {error && <p className="error-message">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddMenu;
