import React, {useState, useEffect} from 'react';
import aboutbannerdeskimg from '../assets/images/about-banner.jpg';
import aboutbannermobimg from '../assets/images/about-banner-mobile.jpg';
import aboutbannervideo from '../assets/images/gallery3.mp4';
import whitelongArrow from '../assets/images/white-longarrow.svg';
import AboutGallery from '../components/AboutGallery';
import LocationSlider from "../components/LocationSlider";
import { LocationData } from "../components/LocationData";
import Slider from "react-slick";
import mapImg from '../assets/images/map.png';
import upBlackarrow from '../assets/images/up-blackarrow.svg';
import upWhiteArrow from '../assets/images/upWhiteArrow.svg';

import {Link } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import Modal from '../components/Modal';
import ShortLargeImage from "../components/ShortLargeImage";
import HomeGallerySlider from "../components/HomeGallerySlider";
import AboutTeam from "../components/AboutTeam";
import Layout from './Layout';


export default function About() {
  const [locations, setLocations] = useState([]);
  const [menus, setmenus] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const fetchLocations = async () => {
    try {
        const response = await axios.get(`${config.BASE_URL}/api/admin/get-locations`, { withCredentials: true });
        
        if(response.data){
            const menuItems = {}
            await Promise.all(response.data.map(async (item, key) => {
                const menuData = await fetchMenuItems(item.menuId)
                menuItems[item._id] = menuData
            }));
            
            setmenus(menuItems);
            setLocations(response.data);
        }

    } catch (err) {
        console.log('Failed to fetch locations');
    } 
  };

  const fetchMenuItems = async (menuId) => {
      try {
          const response = await axios.get(`${config.BASE_URL}/api/admin/get-menu-items-count/${menuId}`, { withCredentials: true });
          return response.data;
      } catch (err) {
          console.log('Failed to fetch menu items');
      }
  };

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2.7,
    slidesToScroll: 1,
    centerMode: false,
    initialSlide: 0, 
    responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2.6,
            slidesToScroll: 1,
            infinite: true,
            centerMode: false,
            arrows:false,
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2.7,
            slidesToScroll: 1,
            infinite: true,
            centerMode: false,
            arrows:false,
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2.6,
            slidesToScroll: 1,
            infinite: true,
            centerMode: false,
            arrows:false,
          }
        },
        {
          breakpoint: 767,
          settings: {
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 0, 
            centerMode: true,
          }
        },
        {
            breakpoint: 480,
            settings: {
              arrows: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0, 
              centerMode: true,
            }
          }
      ]  
  };

  useEffect(() => {
      fetchLocations();
  }, []);

  
  return (
    <>
    <Layout>
      <div className='about-banner position-relative text-white'>
            <picture>
              <source media="(max-width:650px)" srcSet={aboutbannermobimg} />
              <img src={aboutbannerdeskimg} alt="Banner Image" className="position-absolute" />
            </picture>
            
            {/* <video width="100%" autoPlay muted loop className="position-absolute">
                <source src={aboutbannervideo} type="video/mp4" />
            </video> */}
            <div className="container position-relative">
              <div className='page-banner-inner'>
                <div className='page-banner-inner-left'>
                  <div className="our-story-top-sec d-flex ">
                      <h6>OUR COFFEE</h6>
                  </div>
                  <h2>from the source </h2>
                  <div className="imgwithtext d-flex">
                      <img src={whitelongArrow} alt="arrow" />
                      <h2>you</h2>
                  </div>
                </div>
                <div className="about-banner-bottom">
                  <h6>COFFEE</h6>
                  <p>Little Cove Espresso is committed to sourcing coffee sustainably and ethically. We prioritize transparent trade, regenerative agriculture and livelihoods of coffee producers. Coffee is often traded as a commodity in markets with future contracts. These prices fluctuate depending on market conditions and trading. These prices have little or no correlation with what is required for farmers to make a sustainable living from coffee. This is why we publish the prices we pay directly to farmers, removing ourselves from the commodity market.</p>
                </div>
                {/* <Link to="/" className='text-white banner-btn desktop-view'>CONTACT US<img src={upBlackarrow} alt="arrow-icon" /></Link> */}
              </div>
            </div>
      </div>
      <div className='about-text-button'>
        <div className="container">
          <div className='about-text-button-inner'>
            <div className='about-text-button-left'>
                <h5 className='font-18 text-uppercase'>Food</h5>
                <p>Every decision made has sustainability in mind. We always consider the source, the process and the impact. We are also committed to sourcing healthy, ethical and sustainable produce, such as using grass-fed beef from Australia, NZ grass fed lamb, free range eggs and hormone and antibiotic free chicken or Atlantic Salmon. Our food is vibrant, full of flavour and both embodies and innovates the modern brunch experience. Eat well, do well.</p>
            </div>
            <div className='about-text-button-right text-right'>
              <Link to="#" className='common-button-style'>VIEW MENU<img src={upWhiteArrow} alt="arrow-icon" /></Link>
            </div>
          </div>
        </div>
      </div>
      <ShortLargeImage />
      <HomeGallerySlider /> 
      <AboutTeam />      

      <div className="community-sec about-community-sec">
        <div className="comunity-section-inner">
          <div className="community-section-left">
            <h2>View locations</h2>
            <p className='desktop-view'>explore our locations and their unique menus. </p>
          </div>
          <div className="community-section-right">
              
              <Slider {...settings}>
              {
                  locations.map((val, index)=>{
                      return <LocationSlider 
                        key={index}
                        imgsrc={`${config.BASE_URL}${val.featuredImage}`}
                        title={val.title}
                        startTime={val.m_f_startTime}
                        endTime={val.m_f_endTime}
                        setShowModal={setShowModal}
                        setModalContent={setModalContent}
                        menuId={val.menuId}
                        moreLink={`/single-location/${val.slug}`}
                      />
                  })
              }
              </Slider>

              {showModal && (

                <Modal
                    show={showModal}
                    handleClose={() => setShowModal(false)}
                    title={modalContent.title}
                    content={modalContent.content}
                    menubg={modalContent.image}
                    time={modalContent.time}
                    moreLink={modalContent.moreLink}
                    menuItems={modalContent.menuItems}
                    menuId={modalContent.menuId}
                />

              )}
          </div>
        </div>
      </div>
      </Layout>
    </>
  )
}
