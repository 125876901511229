import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import config from '../../config';
import { Calendar } from 'primereact/calendar';
import VideoElement from '../../components/VideoElement';

function UpdateLocationDetail() {
    const { id } = useParams(); // Get the project ID from the URL
    const navigate = useNavigate();
    const [project, setProject] = useState({ title:'', description:'', m_f_startTime: '', m_f_endTime: '', s_s_startTime: '', s_s_endTime: '', addressLine1: '', addressLine2: '', city: '', state: '', country: '', phone_number: '',  galleryImages: [] });
    const [newImages, setNewImages] = useState([]);
    const [bannerImage, setBannerImage] = useState(null);
    const [featuredImage, setFeaturedImage] = useState(null);
    const [video, setVideo] = useState(null);
    const [removeImages, setRemoveImages] = useState([]);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState(''); 
    const fileInputRef = useRef(null);
    const bannerImageRef = useRef(null); // Reference for banner image input
    const featuredImageRef = useRef(null); // Reference for featured image input
    const videoRef = useRef(null);

    const hours = Array.from({ length: 24 * 2 }, (_, i) => {
        const hour = String(Math.floor(i / 2)).padStart(2, "0");
        const minutes = i % 2 === 0 ? "00" : "30";
        return `${hour}:${minutes}`;
      });
    
    useEffect(() => {
        const fetchLocationDetails = async () => {
            try {
                const response = await axios.get(`${config.BASE_URL}/api/admin/get-location/${id}`, { withCredentials: true });
                setProject(response.data); // Set the fetched project details
            } catch (err) {
                setError('Failed to fetch location details');
            }
        };

        fetchLocationDetails();
    }, [id]);

    const handleRemoveImage = async (imageName) => {
        try {
            const response = await axios.post(`${config.BASE_URL}/api/admin/remove-image`, { 
                LocationId: project._id, 
                imageName 
            }, { withCredentials: true });
    
            if (response.status === 200) {
                // Remove image from the project state and track it for removal
                setRemoveImages(prev => [...prev, imageName]); // Add to removeImages array
                
                // Update project state
                setProject(prevProject => {
                    const updatedImages = prevProject.galleryImages.filter(image => image !== imageName);
                    return {
                        ...prevProject,
                        galleryImages: updatedImages,
                        // Check if the removed image was the banner or featured image
                        bannerImage: prevProject.bannerImage === imageName ? null : prevProject.bannerImage,
                        featuredImage: prevProject.featuredImage === imageName ? null : prevProject.featuredImage,
                    };
                });
            } else {
                console.error('Failed to remove image');
            }
        } catch (err) {
            console.error('Error removing image:', err);
        }
    };

    const handleNewImageChange = (e) => {
        setNewImages(e.target.files); // Set new images to be uploaded
    };

    const handleBannerImageChange = (e) => {
        setBannerImage(e.target.files[0]); // Set the banner image
    };

    const handleFeaturedImageChange = (e) => {
        setFeaturedImage(e.target.files[0]); // Set the featured image
    };

    const handleVideoChange = (e) => {
        setVideo(e.target.files[0]); // Set the featured image
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('title', project.title);
        formData.append('description', project.description);

        formData.append('m_f_startTime', project.m_f_startTime);
        formData.append('m_f_endTime', project.m_f_endTime);

        formData.append('s_s_startTime', project.s_s_startTime);
        formData.append('s_s_endTime', project.s_s_endTime);

        formData.append('addressLine1', project.addressLine1);
        formData.append('addressLine2', project.addressLine2);
        formData.append('city', project.city);
        formData.append('state', project.state);
        formData.append('country', project.country);
        formData.append('phone_number', project.phone_number);

        // Send the images to remove, if any
        if (removeImages.length > 0) {
            formData.append('removeImages', JSON.stringify(removeImages));
        }

        // Add new images for gallery
        for (const image of newImages) {
            formData.append('galleryImages', image);
        }

        // Add the banner image if selected
        if (bannerImage) {
            formData.append('bannerImage', bannerImage);
        }

        // Add the featured image if selected
        if (featuredImage) {
            formData.append('featuredImage', featuredImage);
        }

        if (video) {
            formData.append('locationVideo', video);
        }

        try {
            const response = await axios.put(`${config.BASE_URL}/api/admin/update-location/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            });

            // Update the project state with the response data
            setProject(response.data.location); // Assuming the response includes the updated project
            if (fileInputRef.current) {
                fileInputRef.current.value = ''; // Clear the file input for new images
            }
            if (bannerImageRef.current) {
                bannerImageRef.current.value = ''; // Clear the banner image input
            }
            if (featuredImageRef.current) {
                featuredImageRef.current.value = ''; // Clear the featured image input
            }
            if (videoRef.current) {
                videoRef.current.value = ''; // Clear the video input
            }

            setBannerImage(null); // Reset banner image state
            setFeaturedImage(null); // Reset featured image state
            setVideo(null);
            setSuccessMessage('Location updated successfully!'); 
        } catch (err) {
            setError('Failed to update location');
        }
    };


    const renderImageOrVideo = (src) => {
        const extension = src.split(".").pop();
        var mediaType = "";
        const videoExtensions = [
            "mp4",
            "3gp",
            "avi",
            "mkv",
            "webm",
            "mov",
            "flv",
            "wmv",
            "mpeg",
            "mpg",
            "m4v",
          ];
          const imageExtensions = [
            "jpg",
            "jfif",
            "avif",
            "jpeg",
            "svg",
            "png",
            "ico",
            "gif",
            "bmp",
            "webp",
            "tiff",
            "heic",
          ];
    
          if (videoExtensions.includes(extension)) {
            mediaType = "VIDEO";
            
            return (
              <VideoElement url={src}/>
            );
          } else if (imageExtensions.includes(extension)) {
            mediaType = "IMAGE";
            
            return (
              <img src={src} alt="gallery" />
            );
          }
      }
    

    return (
        <div className="dashboard update_detail_wrap">
            <div className="container">
                <h1 className="dash_title">Edit Location</h1>
                <div className="back_btn_wrap">
                    <button className="back-btn" onClick={() => navigate(-1)}>Go Back</button>
                </div>
                {project ? (
                    <form onSubmit={handleFormSubmit} encType="multipart/form-data">
                        <div className="update_details_wrap">
                        <div className="form-group">
                            <label>Location Title</label>
                            <input
                                type="text"
                                value={project.title}
                                onChange={(e) => setProject({ ...project, title: e.target.value })}
                            />
                        </div>

                        <div className="form-group textareaField">
                            <h2>Description</h2>
                            <textarea
                                name="description"
                                value={project.description}
                                onChange={(e) => setProject({ ...project, description: e.target.value })}
                                required
                            />
                        </div>
                            
                        <div className="form-group">

                            <label>M-F Start Time</label>
                            {/* <Calendar id="calendar-timeonly_starttime" name="startTime" value={formData.startTime} onChange={(e) => handleInputChange(e.target)} timeOnly /> */}
                            <select id="m_f_startTime" value={project.m_f_startTime} name="m_f_startTime" onChange={(e) => setProject({ ...project, m_f_startTime: e.target.value })} >
                                {hours.map((hour) => (
                                <option key={hour} value={hour}>
                                    {hour}
                                </option>
                                ))}
                            </select>

                        </div>

                        <div className="form-group">

                            <label>M-F End Time</label>
                            {/* <Calendar id="calendar-timeonly_starttime" name="startTime" value={formData.startTime} onChange={(e) => handleInputChange(e.target)} timeOnly /> */}
                            <select id="m_f_endTime" value={project.m_f_endTime} name="m_f_endTime" onChange={(e) => setProject({ ...project, m_f_endTime: e.target.value })} >
                                {hours.map((hour) => (
                                <option key={hour} value={hour}>
                                    {hour}
                                </option>
                                ))}
                            </select>
                        </div>

                        {/* <div className="form-group">

                            <label>S-S Start Time</label>
                            {/* <Calendar id="calendar-timeonly_starttime" name="startTime" value={formData.startTime} onChange={(e) => handleInputChange(e.target)} timeOnly /> */}
                            {/* <select id="s_s_startTime" value={project.s_s_startTime} name="s_s_startTime" onChange={(e) => setProject({ ...project, s_s_startTime: e.target.value })} >
                                {hours.map((hour) => (
                                <option key={hour} value={hour}>
                                    {hour}
                                </option>
                                ))}
                            </select>
                        </div> */}

                        {/* <div className="form-group">

                            <label>S-S End Time</label> */}
                            {/* <Calendar id="calendar-timeonly_starttime" name="startTime" value={formData.startTime} onChange={(e) => handleInputChange(e.target)} timeOnly /> */}
                            {/* <select id="s_s_endTime" value={project.s_s_endTime} name="s_s_endTime" onChange={(e) => setProject({ ...project, s_s_endTime: e.target.value })} >
                                {hours.map((hour) => (
                                <option key={hour} value={hour}>
                                    {hour}
                                </option>
                                ))}
                            </select>
                        </div> */} 

                        <div className="form-group">
                            <label>Address line 1</label>
                            <input
                                type="text"
                                value={project.addressLine1}
                                onChange={(e) => setProject({ ...project, addressLine1: e.target.value })}
                            />
                        </div>

                        <div className="form-group">
                            <label>Address line 2</label>
                            <input
                                type="text"
                                value={project.addressLine2}
                                onChange={(e) => setProject({ ...project, addressLine2: e.target.value })}
                            />
                        </div>

                        <div className="form-group">
                            <label>City</label>
                            <input
                                type="text"
                                value={project.city}
                                onChange={(e) => setProject({ ...project, city: e.target.value })}
                            />
                        </div>

                        <div className="form-group">
                            <label>State</label>
                            <input
                                type="text"
                                value={project.state}
                                onChange={(e) => setProject({ ...project, state: e.target.value })}
                            />
                        </div>

                        <div className="form-group">
                            <label>Country</label>
                            <input
                                type="text"
                                value={project.country}
                                onChange={(e) => setProject({ ...project, country: e.target.value })}
                            />
                        </div>

                        <div className="form-group">
                            <label>Phone number</label>
                            <input
                                type="text"
                                value={project.phone_number}
                                onChange={(e) => setProject({ ...project, phone_number: e.target.value })}
                            />
                        </div>

                        <div className="form-group">
                            
                            <div className="form-group">
                                <label>Upload Gallery Images</label>
                                <input type="file" multiple onChange={handleNewImageChange} ref={fileInputRef} />
                            </div>
                            <div className="detail_item_inner gallery_inner">
                            <div className="detail_img_grid">
                                {project?.galleryImages && project.galleryImages.map((image, index) => (
                                    <div className="detail_image_item" key={index}>
                                        <div className="detail_img_item">
                                        {/* <img src={`${config.BASE_URL}${image}`} alt={`Project ${index}`} /> */}
                                        {renderImageOrVideo(`${config.BASE_URL}${image}`)}

                                        </div>
                                        <button className="remove_btn" type="button" onClick={() => handleRemoveImage(image)}>Remove</button>
                                    </div>
                                ))}
                            </div>
                            </div>
                        </div>
                        <div className="form-group">
                        <div className="detail_banner_wrap">
                        
                        
                            <div className="detail_img_grid">
                            <div className="upload_item">
                            <label>Mobile featured Image</label>
                        
                                <input type="file" onChange={handleBannerImageChange} ref={bannerImageRef} />
                            </div>
                                {project.bannerImage ? (
                                    <div className="detail_img_item">
                                        <div className="banner_img_wrap">
                                        <img src={`${config.BASE_URL}${project.bannerImage}`} alt="Banner" />
                                        </div>
                                        <button className="remove_btn" type="button" onClick={() => handleRemoveImage(project.bannerImage)}>Remove</button>
                                    </div>
                                ) : (
                                    <p>No image available.</p>
                                )}
                            </div>
                            <div className="detail_img_grid">
                            
                                <div className="upload_item">
                                <label>Featured Image</label>
                                    <input type="file" onChange={handleFeaturedImageChange} ref={featuredImageRef} />
                                </div>
                                <div className="detail_img_grid">
                                    {project.featuredImage ? (
                                        <div className="detail_img_item">
                                            <div className="banner_img_wrap">
                                            <img src={`${config.BASE_URL}${project.featuredImage}`} alt="Featured" />
                                            </div>
                                            <button className="remove_btn" type="button" onClick={() => handleRemoveImage(project.featuredImage)}>Remove</button>
                                        </div>
                                    ) : (
                                        <p>No image available.</p>
                                    )}
                                </div>
                                
                            </div>

                            {/* <div className="detail_img_grid">
                                <div className="upload_item">
                                <label>Video</label>
                                    <input type="file" onChange={handleVideoChange} ref={videoRef} />
                                </div>
                                <div className="detail_img_grid">
                                    {project.locationVideo ? (
                                        <div className="detail_img_item">
                                            <div>
                                                <video width="100%" autoPlay muted loop className="position-relative">
                                                    <source src={`${config.BASE_URL}${project.locationVideo}`} type="video/mp4" />
                                                </video>
                                            </div>
                                            <button className="remove_btn" type="button" onClick={() => handleRemoveImage(project.locationVideo)}>Remove</button>
                                        </div>
                                    ) : (
                                        <p>No video available.</p>
                                    )}
                                </div>
                                
                            </div> */}
                        </div>
                        </div>
                    
                    <div className="update_btn_wrap">
                        <button className="update_btn" type="submit">Update Location</button>
                        </div>
                        {successMessage && <p className="success-message">{successMessage}</p>}
                        {error && <p className="error-message">{error}</p>}
                        </div>
                    </form>
                ) : (
                    <h5>Location not found!</h5>
                )}
            </div>
        </div>
    ); 
}

export default UpdateLocationDetail;
