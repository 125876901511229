import React, { useEffect, useRef } from 'react';
import Gallery1 from '../assets/images/partner-event-1.jpg';
import Gallery2 from '../assets/images/partner-event-2.jpg';
import Gallery3 from '../assets/images/partner-event-3.jpg';
import Gallery4 from '../assets/images/partner-event-4.jpg';
import Gallery5 from '../assets/images/partner-event-5.jpg';
import upWhiteArrow from '../assets/images/upWhiteArrow.svg';
import {Link } from 'react-router-dom';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import config from '../config';
gsap.registerPlugin(ScrollTrigger);

function EventGallery({events}) {
const isImage = (src) => {
    return /\.(jpg|jpeg|png|gif)$/.test(src); // You can add more extensions if needed
    };
  const pinContainerRef = useRef(null);  
  useEffect(() => {
    let ctx = gsap.context(() => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: pinContainerRef.current,
          start: "top +=300",
          end: "+=1000",
          scrub: true,
          markers: false,
        }
      });

      tl.to(".gallery_image_1_1", {
        duration: 1,
        x: 0,
        y: -200,
      }, 0);     
      tl.to(".gallery_image_2_1", {
        duration: 1,
        x: -80,
        y: 0,
      }, 0);
      tl.to(".gallery_image_3_1", {
        duration: 1,
        x: 80,
        y: 0,
      }, 0);
      tl.to(".gallery_image_4_1", {
        duration: 1,
        x: 0,
        y: -110,
      }, 0);
    });

    return () => {
      ctx.revert();
    };
  }, []);

  return (
    <div className='partner_gallery_event_sec'>
      <div className='cont'>
        <div className='partner_gallery_top'>
          <div className='partner_gallery_top_inner'>
            <div className='partner_gallery_eventbox'>
              <div className="partner_gallery_image position-relative gallery_image_1 z-2">
                <img src={`${config.BASE_URL}${events[0]?.featuredImage}`} alt="Gallery" class="w-100 position-absolute" />
                <div className="position-absolute gallery-botm-desc d-flex text-white z-2">
                    <h2>{events[0]?.title}</h2>
                    {/* <p className="text-white">Guests were invited to explore Bobbi Brown products in a custom activation by Little Cove Espresso. </p> */}
                </div>
              </div>
            </div>
            <div className='partner_gallery_eventbox'>
              <div className='gallery_eventbox_toprightbox d-flex'>
                <div className='gallery_eventrightbox_left'>
                  <img src={`${config.BASE_URL}${events[0]?.galleryImages[0]}`} alt="Gallery" class="w-100" />
                </div>
                <div className='gallery_eventrightbox_right'>
                  <img src={`${config.BASE_URL}${events[0]?.galleryImages[1]}`} alt="Gallery" class="w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='partner_gallery_bottom'>
          <div className='partner_gallery_bottom_inner d-grid'>
            <div className='partner_gallery_bottom_inner_left'>
              <img src={`${config.BASE_URL}${events[0]?.galleryImages[2]}`} alt="Gallery" class="w-100" />
              <div className='partner_event_gallerybox4'>
                <h2>Get started:</h2>
                <p>Work with LCE to create a custom experience or private event. </p>
                <Link to="#" className='common-button-style'>CONTACT US<img src={upWhiteArrow} alt="arrow-icon" /></Link>  
              </div>
            </div>
            <div className='partner_gallery_bottom_inner_right'>
              <div className="partner_gallery_image position-relative gallery_image_5 z-2">
                <img src={`${config.BASE_URL}${events[0]?.galleryImages[3]}`} alt="Gallery" class="w-100 position-absolute" />
                <div className="position-absolute gallery-botm-desc d-flex text-white z-2">
                    <p className="text-white">{events[0]?.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>        
    </div>
  </div>
  );
}

export default EventGallery;
