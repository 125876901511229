import React, { useState, useEffect } from 'react';
import upBlackarrow from "../assets/images/up-blackarrow.svg";
import locationdesktopBanner from '../assets/images/kenedy-townbanner.jpg';
import locationmobileBanner from '../assets/images/location-mobile-banner.png';
import aboutbannervideo from '../assets/images/gallery3.mp4';
import locationGallery1 from '../assets/images/location-gallery1.jpg';
import locationGallery2 from '../assets/images/location-gallery2.jpg';
import locationGallery4 from '../assets/images/location-gallery4.jpg';
import LocationSlider from '../components/LocationSlider';
import { LocationData } from '../components/LocationData';
import Slider from 'react-slick';
import { Link, useParams } from 'react-router-dom';
import axios from "axios";
import config from '../config';
import Modal from '../components/Modal';
import VideoElement from '../components/VideoElement';
import Layout from './Layout';


export default function SingleLocations() {
  const [location, setLocation] = useState();
  const [locations, setLocations] = useState([]);
  const [menus, setmenus] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const { id } = useParams();

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2.7,
    slidesToScroll: 1,
    centerMode: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2.6,
          slidesToScroll: 1,
          infinite: true,
          centerMode: false,
          arrows: false,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.7,
          slidesToScroll: 1,
          infinite: true,
          centerMode: false,
          arrows: false,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2.6,
          slidesToScroll: 1,
          infinite: true,
          centerMode: false,
          arrows: false,
        }
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          centerMode: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          centerMode: true,
        }
      }
    ]
  };


  const fetchLocation = async () => {
    try {
      const response = await axios.get(`${config.BASE_URL}/api/admin/single-location/${id}`, { withCredentials: true });

      if (response.data) {
        const menuItems = {}
        const menuData = await fetchMenuItems(response?.data?.menuId)
        menuItems[response?.data?._id] = menuData;

        setmenus(menuItems);
        setLocation(response.data);
      }

    } catch (err) {
      console.log('Failed to fetch location');
    }
  };

  const fetchLocations = async () => {
    try {
      const response = await axios.get(`${config.BASE_URL}/api/admin/get-locations`, { withCredentials: true });

      if (response.data) {
        const menuItems = {}
        await Promise.all(response.data.map(async (item, key) => {
          const menuData = await fetchMenuItems(item.menuId)
          menuItems[item._id] = menuData
        }));

        setLocations(response.data);
      }

    } catch (err) {
      console.log('Failed to fetch locations');
    }
  };

  const fetchMenuItems = async (menuId) => {
    try {
      const response = await axios.get(`${config.BASE_URL}/api/admin/get-menu-items-count/${menuId}`, { withCredentials: true });
      return response.data;
    } catch (err) {
      console.log('Failed to fetch menu items');
    }
  };

  const renderStatus = (location) => {
    // const startTime = new Date(location.m_f_startTime);
    // const endTime = new Date(location.m_f_endTime);

    const startTime = location.m_f_startTime;
    const endTime = location.m_f_endTime;

    const currentDate = new Date();

    // const start = startTime.getHours();
    // const end = endTime.getHours();

    const start = parseInt(startTime);
    const end = parseInt(endTime);

    const current = currentDate.getHours();

    var locationStatus = '';

    if (start <= current && current < end) {
      locationStatus = 'open';
    }

    if (start <= current && current < end) {
      locationStatus = 'open';
    } else {
      locationStatus = 'closed';
    }

    return locationStatus;
  }

  const renderTime = (location) => {
    // const startTime = new Date(location.startTime);
    // const endTime = new Date(location.endTime);

    const start = parseInt(location.m_f_startTime);
    const end = parseInt(location.m_f_endTime);

    // const start = startTime.getHours();
    // const end = endTime.getHours();


    return `${start % 12}${start > 12 ? 'pm' : 'am'} - ${end % 12}${end > 12 ? "pm" : "am"}`;
  }

  const openMenu = (location) => {
    const status = renderStatus(location);
    const time = renderTime(location);

    setModalContent({
      title: location.title,
      image: config.BASE_URL + location.featuredImage,
      status: status,
      time: time,
      menuId: location.menuId
    });

    setShowModal(true);
  }

  const renderImageOrVideo = (src) => {
    const extension = src.split(".").pop();
    var mediaType = "";
    const videoExtensions = [
      "mp4",
      "3gp",
      "avi",
      "mkv",
      "webm",
      "mov",
      "flv",
      "wmv",
      "mpeg",
      "mpg",
      "m4v",
    ];
    const imageExtensions = [
      "jpg",
      "jfif",
      "avif",
      "jpeg",
      "svg",
      "png",
      "ico",
      "gif",
      "bmp",
      "webp",
      "tiff",
      "heic",
    ];

    if (videoExtensions.includes(extension)) {
      mediaType = "VIDEO";

      return (
        <VideoElement url={src} />
      );
    } else if (imageExtensions.includes(extension)) {
      mediaType = "IMAGE";

      return (
        <img src={src} alt="gallery" />
      );
    }
  }

  useEffect(() => {
    fetchLocations();
    fetchLocation();
  }, []);

  return (
    <Layout>
    {location && (<>
      <div className='location-banner page-banner position-relative text-white' style={{
        backgroundImage: `url(${config.BASE_URL}/${isMobile ? location.bannerImage : location.featuredImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
        <div className='container'>
          <div className='location-banner-inner d-flex justify-content-between'>
            <div className='locatioon-banner-left'>
              <h1>{location.title}</h1>
              <span class="menu-status open">{renderStatus(location)}</span>
              <div className='status-sec d-flex' style={{ gap: "10px" }}>
                {/* <span class="menu-time">M-F: 10-6pm</span>
                        <span class="menu-time">S+S: 10-6pm</span> */}
                <span className='menu-time'>
                  {`Monday-Sunday: ${(String(location.m_f_startTime).split(":")[0] % 12)}:${(String(location.m_f_startTime).split(":")[1])}`}{String(location.m_f_startTime).split(":")[0] > 12 ? "pm" : "am"}-{`${(String(location.m_f_endTime).split(":")[0] % 12)}:${(String(location.m_f_endTime).split(":")[1])}${String(location.m_f_endTime).split(":")[0] > 12 ? "pm" : "am"}`}
                </span>
                {/* <span className='menu-time'>
                  {`S+S: ${(String(location.s_s_startTime).split(":")[0] % 12)}:${(String(location.s_s_startTime).split(":")[1])}-`}{`${(String(location.s_s_endTime).split(":")[0] % 12)}:${(String(location.s_s_endTime).split(":")[1])}${String(location.s_s_endTime).split(":")[0] > 12 ? "pm" : "am"}`}
                </span> */}
                {/* <span className='menu-time'>
                          {`S+S: ${location.s_s_startTime % 12 }-`}{`${location.s_s_endTime % 12}${location.s_s_endTime > 12 ? "pm" : "am"}`}
                        </span> */}

                {/* <span class="menu-time">{renderTime(location)}</span> */}
              </div>
            </div>
            <div className='location-banner-right'>
              <a href={`https://www.google.com/maps/?q=${location.addressLine1} ${location.addressLine2} ${location.city} ${location.state} ${location.country}`} target='blank' className='text-white banner-btn'>MAP<img src={upBlackarrow} alt="arrow-icon" /></a>
              <a onClick={() => openMenu(location)} className='text-white banner-btn desktop-view'>MENU<img src={upBlackarrow} alt="arrow-icon" /></a>
            </div>
          </div>
        </div>
      </div>
      <div className='address-sec'>
        <div className='container'>
          <div className='addres-sec-main d-flex justify-content-between'>
            <div className='address-sec-left'>
              <p>{location.description}</p>
            </div>
            <div className='address-sec-right desktop-view'>
              <span className='address-block'>
                <a className='locationPhoneNumber'>{location?.phone_number}
                <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.78125 0H2.21875C1.44238 0 0.8125 0.629883 0.8125 1.40625V13.5938C0.8125 14.3701 1.44238 15 2.21875 15H8.78125C9.55762 15 10.1875 14.3701 10.1875 13.5938V1.40625C10.1875 0.629883 9.55762 0 8.78125 0ZM5.5 14.0625C4.98145 14.0625 4.5625 13.6436 4.5625 13.125C4.5625 12.6064 4.98145 12.1875 5.5 12.1875C6.01855 12.1875 6.4375 12.6064 6.4375 13.125C6.4375 13.6436 6.01855 14.0625 5.5 14.0625ZM8.78125 10.8984C8.78125 11.0918 8.62305 11.25 8.42969 11.25H2.57031C2.37695 11.25 2.21875 11.0918 2.21875 10.8984V1.75781C2.21875 1.56445 2.37695 1.40625 2.57031 1.40625H8.42969C8.62305 1.40625 8.78125 1.56445 8.78125 1.75781V10.8984Z" fill="white"/>
                </svg>
                </a>
                <p>
                {location.addressLine1},<br></br>
                {location.addressLine2},<br></br>
                {location.city}, {location.state}, {location.country}
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
      {location !== undefined && (
        <div className='location-gallery'>
          <div className='container'>
            <div className='location-gallery-sec d-flex'>
              <div className='location-gallery-left d-flex'>

                {(location?.galleryImages.length) > 0 && (
                  // <img src={config.BASE_URL + location?.galleryImages[0]} alt="galley" />
                  renderImageOrVideo(config.BASE_URL + location?.galleryImages[0])
                )}

                {(location?.galleryImages.length > 1) && (
                  // <img src={config.BASE_URL + location?.galleryImages[1]} alt="galley" />
                  renderImageOrVideo(config.BASE_URL + location?.galleryImages[1])
                )}

              </div>
              {/* {location?.locationVideo && ( */}
              <div className='location-gallery-middle position-relative'>
                {/* <video width="100%" autoPlay muted loop className="position-relative">
                          <source src={config.BASE_URL + location.locationVideo} type="video/mp4" />
                      </video> */}

                {(location?.galleryImages.length > 2) && (
                  // <img src={config.BASE_URL + location?.galleryImages[2]} alt="galley" />
                  renderImageOrVideo(config.BASE_URL + location?.galleryImages[2])
                )}

                <a onClick={() => openMenu(location)} className='text-white view-menu-btn position-absolute'>VIEW MENU<img src={upBlackarrow} alt="arrow-icon" /></a>
              </div>
              {/* )} */}
              <div className='location-gallery-right'>
                {(location?.galleryImages.length > 3) && (
                  // <img src={config.BASE_URL + location?.galleryImages[2]} alt="galley" />
                  renderImageOrVideo(config.BASE_URL + location?.galleryImages[3])
                )}

                {(location?.galleryImages.length) > 4 && (
                  renderImageOrVideo(config.BASE_URL + location?.galleryImages[4])
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='location-map-sec map-sec d-flex mobile-view'>
        <div className='map-left'>
          <h2>Partner with LCE</h2>
          <p>Coordinate corporate, brand, and private events with our team.</p>
        </div>
        <div className='map-right d-flex'>
          <Link to="tel:+1-111-1111" className='text-black'>+1-111-1111<img src={upBlackarrow} alt="arrow-icon" /></Link>
          <Link to="mailto:sai@littlecoveespresso.com" className='text-black'>sai@littlecoveespresso.com <img src={upBlackarrow} alt="arrow-icon" /></Link>
        </div>
      </div>
      <div className="community-sec about-community-sec single-other-location">
        <div className="comunity-section-inner">
          <div className="community-section-left">
            <h2 className='desktop-view'>Other locations</h2>
            <h6>OTHER LOCATIONS</h6>
            <p className='desktop-view'>explore our locations and their unique menus. </p>
          </div>
          <div className="community-section-right">

            <Slider {...settings}>
              {
                locations.map((val, index) => {
                  return <LocationSlider
                    key={index}
                    imgsrc={`${config.BASE_URL}${val.featuredImage}`}
                    title={val.title}
                    startTime={val.m_f_startTime}
                    endTime={val.m_f_endTime}
                    setShowModal={setShowModal}
                    setModalContent={setModalContent}
                    menuId={val.menuId}
                    moreLink={`/single-location/${val.slug}`}
                  />
                })
              }
            </Slider>
          </div>

          {showModal && (

            <Modal
              show={showModal}
              handleClose={() => setShowModal(false)}
              title={modalContent.title}
              content={modalContent.content}
              menubg={modalContent.image}
              time={modalContent.time}
              moreLink={modalContent.moreLink}
              menuItems={modalContent.menuItems}
              menuId={modalContent.menuId}
            />
          )}
        </div>
      </div>
    </>
    )}
  </Layout>
  )
}
