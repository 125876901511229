import React from 'react';
import partnervideo from '../assets/images/partner-video.mp4';
import slider1 from '../assets/images/eventslider1.jpg';
import Slider from 'react-slick';
import PartnerGalleryTop, { PartnerGalleryBottom } from '../components/PartnerGallery';
import upBlackarrow from '../assets/images/up-blackarrow.svg';
import {Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import config from '../config';
import axios from 'axios';
import BackgroundImage from "../assets/images/EventBackground.jpg"
import EventGallery from "../components/EventGallery";
import Layout from './Layout';


export default function Partnerwithus() {
    const [events, setEvents] = useState([]);

    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2.5,
        slidesToScroll: 1,
        centerMode: false, 
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2.5, 
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.025, 
                    slidesToScroll: 1,
                }
            }
        ]
    };
    
    const fetchEvents = async () => {
        try {
            const response = await axios.get(`${config.BASE_URL}/api/admin/get-events`, { withCredentials: true });
            
            if(response.data){
                setEvents(response.data);
            }

        } catch (err) {
            console.log('Failed to fetch locations');
        } 
    };

    useEffect(() => {
        fetchEvents();
    }, []);
    console.log("events",events)
    
  return (
    <>
    <Layout>
        <div className='partner-banner page-banner position-relative text-white'>
                            <img src={BackgroundImage} alt="BackgroundImage" className="position-absolute" />

            {/* <video width="100%" autoPlay muted loop className="position-absolute desktop-view">
                <source src={partnervideo} type="video/mp4" />
            </video> */}
            <div className="container">
                <div className='page-banner-inner d-flex justify-content-between align-items-end'>
                    <div className='page-banner-inner-left'>
                        <div className="our-story-top-sec d-flex ">
                            <h6>PARTNER WITH US</h6>
                        </div>
                        <h1>interested in hosting something special? </h1>
                    </div>
                    <Link to="/" className='text-white banner-btn desktop-view'>CONTACT US<img src={upBlackarrow} alt="arrow-icon" /></Link>
                </div>
            </div>
        </div>
        <div className='partnet-video-sec d-flex align-items-center mobile-view'>
            <div className='partner-video-left position-relative'>
                {/* <img src={BackgroundImage} alt="BackgroundImage" className="position-absolute" /> */}
                <video width="100%" autoPlay muted loop className="position-absolute">
                    <source src={partnervideo} type="video/mp4" />
                </video>
                <div className='partner-event-list d-flex position-absolute'>
                    <span className='text-white'>HOSPITALITY PARTNERSHIPS</span>
                    <span className='text-white'>PRIVATE EVENTS</span>
                    <span className='text-white'>BRAND EVENTS</span>
                </div>
            </div>
            <div className='partner-video-right'>
                <p>Work with LCE to create a custom experience or private event. We partner with hospitality companies, brands, and corporations to host events, popups, and more. </p>
            </div>
        </div> 
        <div className='partner-recent-event mobile-view'>
            <h6>RECENT EVENTS</h6>
            {events.length > 0 && (
                <Slider {...settings}>
                    {events.map((event, index) => (
                        <div className='slider-items' key={index}>
                            <img src={`${config.BASE_URL}${event.featuredImage}`} alt="slider" className='position-absolute'/>
                            <h2>{event.title}</h2>
                            <p>{event.description}</p>
                        </div>
                    ))}
                </Slider>
            )}
        </div>
        <div className='partner-top-sec desktop-view'>
            <div className='container'>
                <p>Work with LCE to create a custom experience or private event. We partner with hospitality companies, brands, and corporations to host events, popups, and more. </p>
                <h6>RECENT EVENTS</h6>
            </div>
        </div>
        <div className='partner_gallery_sec'>
            <div className='container'> 
                <EventGallery events={events}/>               
                {/* {events.length > 0 && ( <>
                    <PartnerGalleryTop events={events} />
                    <PartnerGalleryBottom events={events} />
                </> )} */}
            </div>
        </div>
        </Layout>
    </>
  )
}
