import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom"; 
import config from '../../../src/config';

function AdminDashboard({onLogout}) {
    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axios.get(`${config.BASE_URL}/api/admin/get-locations`, { withCredentials: true });
                setLocations(response.data); // Set the fetched projects to state
            } catch (err) {
                setError('Failed to fetch projects');
            } finally {
                setLoading(false);
            }
        };

        fetchProjects();
    }, []);

    return (
        <>
        <div className="dashboard">
        <div className="container">
            <h1 className="dash_title">Dashboard</h1>
            <div className="main-section_dashboard">
        <div class="content">
            <div class="cards">
            <div class="card red">
            <i class="fas fa-folder-open">
            </i>
            <div class="number">
            {locations.length }
            </div>
            <div class="label">
            Total Locations
            </div>
            </div>
            </div>
            <div class="user-info">
            {locations.map((location, index) => (
            <div className="details"  key={location._id}>
            <div className="name">
            {location.title}
            </div>
            <div className="tweet">
            {location.brandDirection}
            </div>
            </div>
            ))}
            <i class="fab fa-twitter">
            </i>
            </div>
        </div>
            </div>
        </div>
        </div>
        </>
    );
}

export default AdminDashboard;
