import React from "react";
import DashboardHeader from '../../components/admincomponents/DashboardHeader';
import UpdateMenuDetail from '../../components/admincomponents/UpdateMenuDetail';

function UpdateMenu({onLogout}) {
  return (
    <>
      <DashboardHeader onLogout={onLogout}/>
      <UpdateMenuDetail />            
    </>
  );
}

export default UpdateMenu;
