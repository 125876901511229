import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom"; 
import config from '../../config';

function LocationsList() {
    const [locations, setLocations] = useState([]);
    const [menus, setmenus] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const fetchLocations = async () => {
        try {
            const response = await axios.get(`${config.BASE_URL}/api/admin/get-locations`, { withCredentials: true });
            
            if(response.data){
                const menuItems = {}
                await Promise.all(response.data.map(async (item, key) => {
                    const menuData = await fetchMenuItems(item.menuId)
                    menuItems[item._id] = menuData
                }));
                
                setmenus(menuItems);
                setLocations(response.data);
            }

        } catch (err) {
            setError('Failed to fetch locations');
        } finally {
            setLoading(false);
        }
    };

    const fetchMenuItems = async (menuId) => {
        try {
            const response = await axios.get(`${config.BASE_URL}/api/admin/get-menu-items-count/${menuId}`, { withCredentials: true });
            return response.data;
        } catch (err) {
            setError('Failed to fetch menu items');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLocations();
    }, []);

    return (
        <>
            <div className="dashboard">
                <div className="container">
                    <h1 className="dash_title">Locations</h1>
                    <div className="main-section">
                        <div className="projects">
                            <div className="projects-inner">
                                <header className="projects-header">
                                    <div className="title">
                                        Total 
                                        <div className="count"> {locations.length } Locations</div>
                                    </div>
                                    <div className="project_search">
                                        <form action="">
                                            <input type="text" name="search" placeholder="Search projects" />
                                            <button type="submit" className="btn">Search</button>
                                        </form>
                                    </div>
                                    <div className="add_project">
                                    <Link to="/admin/add-location">Add Location</Link>
                                    </div>
                                </header>
                                <table className="projects-table">
                                    <thead>
                                        <tr>
                                            <th>Location Title</th>
                                            <th>Menus</th>
                                            <th>Timing</th>
                                            <th>Address</th>
                                            <th className="text-right">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (
                                            <tr>
                                                <td colSpan="4">Loading...</td>
                                            </tr>
                                        ) : error ? (
                                            <tr>
                                                <td colSpan="4">{error}</td>
                                            </tr>
                                        ) : (
                                            locations.map((location, index) => ( 
                                                <tr key={location._id}>
                                                    <td><p>{location.title}</p></td>
                                                    <td>
                                                        <p>{menus[location._id] && menus[location._id].length}</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                        {`M-F: ${location.m_f_startTime}:${location.m_f_endTime}`}
                                                        {` S+S: ${location.s_s_startTime}:${location.s_s_endTime}`}
                                                        </p>
                                                    </td>
                                                    <td><p>{`${location.addressLine1}, ${location.city}, ${location.country}`}</p></td>
                                                    <td>
                                                        {location.menuId && (
                                                            <Link to={`/admin/menu/${location.menuId}`} className="view_detail">View Menu</Link>
                                                        )}
                                                        {' '}
                                                        <Link to={`/admin/location/${location._id}`} className="view_detail">View Details</Link>
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LocationsList;
