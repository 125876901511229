import React, { useEffect, useState } from "react";
import axios from "axios"; 
import { useParams, useNavigate, Link } from "react-router-dom";
import config from '../../config';
import VideoElement from "../VideoElement";

function EventDetails() {
    const { id } = useParams(); // Get the project ID from the URL
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [menus, setMenus] = useState(0);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const fetchEventDetails = async () => {
        try {
            const response = await axios.get(`${config.BASE_URL}/api/admin/get-event/${id}`, { withCredentials: true });
            setEvent(response.data);
            
        } catch (err) {
            setError('Failed to fetch location details');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEventDetails();
    }, [id]);

    const handleDelete = async () => {
        try {
            await axios.delete(`${config.BASE_URL}/api/admin/delete-event/${id}`, { withCredentials: true });
            // Navigate back to the project list or show a success message
            navigate('/admin/events'); 
        } catch (err) {
            setError('Failed to delete the event');
        }
    };

    const renderImageOrVideo = (src) => {
        const extension = src.split(".").pop();
        var mediaType = "";
        const videoExtensions = [
            "mp4",
            "3gp",
            "avi",
            "mkv",
            "webm",
            "mov",
            "flv",
            "wmv",
            "mpeg",
            "mpg",
            "m4v",
          ];
          const imageExtensions = [
            "jpg",
            "jfif",
            "avif",
            "jpeg",
            "svg",
            "png",
            "ico",
            "gif",
            "bmp",
            "webp",
            "tiff",
            "heic",
          ];
    
          if (videoExtensions.includes(extension)) {
            mediaType = "VIDEO";
            
            return (
              <VideoElement url={src}/>
            );
          } else if (imageExtensions.includes(extension)) {
            mediaType = "IMAGE";
            
            return (
              <img src={src} alt="gallery" />
            );
          }
      }

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="dashboard project_detail_wrap">
            <div className="container">
                <h1 className="dash_title">Dashboard</h1>
                <div className="back_btn_wrap">
                <button className="back-btn" onClick={() => navigate(-1)}>Go Back</button>
                </div>
                <div className="main-section">
                    <div className="projects">
                        <div className="projects-inner">
                            <div className="detail_item">
                                <h2>Event Title</h2>
                                <div className="detail_item_inner">
                                    <p>{event.title}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Description</h2>
                                <div className="detail_item_inner">
                                    <p>{event.description}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        {event.featuredImage ? ( // No .length because it's a string
                                            <div className="detail_img_item">
                                                <h2>Featured Image</h2>
                                                <div className="banner_img_wrap">
                                                <img src={`${config.BASE_URL}${event.featuredImage}`} alt="Featured" />
                                                </div>
                                            </div>
                                        ) : (
                                            <p>No image available.</p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Gallery Images</h2>
                                <div className="detail_item_inner gallery_inner">
                                    <div className="detail_img_grid">
                                        {event.galleryImages && event.galleryImages.length > 0 ? (
                                            event.galleryImages.map((image, index) => (
                                                <div className="detail_img_item" key={index}>
                                                    {renderImageOrVideo(`${config.BASE_URL}${image}`)}

                                                    {/* <img src={`${config.BASE_URL}${image}`} alt={`Project image ${index}`} /> */}
                                                </div>
                                            ))
                                        ) : (
                                            <p>No images available.</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                           
                            <div className="detail_project_btn_wrap">
                                <div className="detail_edit_del">
                                    <div className="btn_item">
                                        <button className="detail_btns" onClick={handleDelete}>Delete</button>
                                    </div>
                                    <div className="btn_item">
                                        <Link to={`/admin/update-event/${event._id}`} className="update_detail detail_btns">Edit</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EventDetails;
